unit Unit5;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs;

type
  TForm5 = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form5: TForm5;

implementation

{$R *.dfm}

procedure TForm5.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Form5';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
  finally
  end;
end;

end.
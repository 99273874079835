unit Ufrmmain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.Grids, System.NetEncoding, System.IOUtils, 
  WEBLib.REST, DB, JSONDataset, WEBLib.CDS, WEBLib.DB, WEBLib.ExtCtrls,uodoo,WEBLib.JSON,unit7;

type
  TfrmMain = class(TForm)
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    btAnamnese: TButton;
    btMedicaments: TButton;
    WebButton4: TButton;
    WebListBox1: TListBox;
    WebListBox2: TListBox;
    WebButton5: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    // procedure WebStringGrid1Click(Sender: TObject);

    procedure btAnamneseClick(Sender: TObject);
    procedure btMedicamentsClick(Sender: TObject);
    procedure WebListBox2Click(Sender: TObject);
     [async]
    procedure WebButton4Click(Sender: TObject); async;

    procedure WebListBox1Change(Sender: TObject);
    procedure wrcpatientRequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
   
    procedure frmMainShow(Sender: TObject);
     [async]
    procedure WebButton7Click(Sender: TObject); async;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;


var
  frmMain: TfrmMain;
   headers: TCoreCloudHeaders;


implementation

uses

ufrmcible, ufrmprestationsmois, ufrmmedicaments, ufrmanamnese, Ufrmlogin;

{$R *.dfm}

type

  TSIListItem = record
      id : integer;
      name : String;
  end;


procedure TfrmMain.WebButton7Click(Sender: TObject);
var
res:TJSONArray;
I:integer;
ligne:TJSONObject;
begin
res:=await(odoo.odooread('res.users',''));

for i := 0 to res.Count - 1 do
    begin
      ligne := res.Items[i] as TJSONObject;
      name := ligne.Get('name').JsonValue.Value;
      console.log(name);
    end;

end;

procedure TfrmMain.frmMainShow(Sender: TObject);
begin


end;

procedure TfrmMain.wrcpatientRequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
var

  patients: TJSArray;
  patient: JSValue;
  i: integer;

begin
  patients := TJSArray(TJSJSON.parseObject(AResponse));
  i:=1;
  WebListBox2.items.clear;
  for patient in patients do
  begin


    i:=i+1;
  end;
  //console.log('WC Array[3] is: '+string(TJSObject(WC_Array[3])['name'])+' / '+string(TJSObject(WC_Array[3])['type']));
end;

procedure TfrmMain.WebListBox1Change(Sender: TObject);
begin
  //wrcpatient.HttpsGet('https://bidb.soluceinfo.be/patient?tournee='+ string(Integer( WebListBox1.Items.Objects[WebListBox1.ItemIndex])), headers);
end;

procedure TfrmMain.WebButton4Click(Sender: TObject);
var
frmCible : TfrmCible;
frmPrestationsmois : TfrmPrestationsmois;
iprestation:integer;
prestation:TJSONObject;
prestations:TJSONArray;
begin
{
  frmCible:=TfrmCible.CreateNew;
  frmCible.Popup:=True;
  frmCible.PopupOpacity:=1;
    frmCible.showmodal;
}

    frmPrestationsmois:=TfrmPrestationsmois.CreateNew;
     prestations:=await(odoo.odooread('docinfi.typeprestation',''));
     //frmPrestationsmois.wsgPrest.width:=Integer(window.screen['width'])-50;
     //frmPrestationsmois.wsgPrest.height:=Integer(window.screen['height'])-50-frmPrestationsmois.wsgPrest.Top;
     frmPrestationsmois.wsgPrest.rowcount:=prestations.Count+1;
  for iprestation := 0 to prestations.Count - 1 do
    begin
      prestation := prestations.Items[iprestation] as TJSONObject;
      frmPrestationsmois.wsgPrest.cells[0,iprestation+1]:=prestation.Get('name').JsonValue.Value;
      
    end;
    frmPrestationsmois.width:=Integer(window.screen['width']);
    frmPrestationsmois.height:=Integer(window.screen['height']);
    frmPrestationsmois.prestations:=prestations;
    frmPrestationsmois.showmodal;  
end;  


procedure TfrmMain.WebListBox2Click(Sender: TObject);
begin
  
end;

procedure TfrmMain.btMedicamentsClick(Sender: TObject);
var
frmMedicaments : TfrmMedicaments;
begin
  frmMedicaments:=TfrmMedicaments.CreateNew;
    frmMedicaments.showmodal;
end;

procedure TfrmMain.btAnamneseClick(Sender: TObject);
var
frmAnamnese2 : TfrmAnamnese2;

begin
    frmAnamnese2:=TfrmAnamnese2.CreateNew;
    frmAnamnese2.showmodal;  
end;



{
procedure TfrmMain.WebStringGrid1Click(Sender: TObject);
var
form2 : TForm2;
form3 : TForm3;
td: TJSHTMLElement;

begin
  if WebStringGrid1.Selection.Top=1 then
  begin
    form2:=TForm2.CreateNew;
    form2.showmodal;
    td := webstringgrid1.CellElements[1,1];    
    td.style.setProperty('background-color','red');
  end;  
  if WebStringGrid1.Selection.Top=2 then
  begin
    form3:=TForm3.CreateNew;
    form3.showmodal;
  end;  
end;
}
procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  btAnamnese := TButton.Create(Self);
  btMedicaments := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebListBox1 := TListBox.Create(Self);
  WebListBox2 := TListBox.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  btAnamnese.BeforeLoadDFMValues;
  btMedicaments.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebListBox1.BeforeLoadDFMValues;
  WebListBox2.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 562;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 1106;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Tournées';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -18;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 14;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 22;
    WebLabel1.Left := 15;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 23;
    WebLabel1.Width := 77;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Patients';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -18;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 14;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 26;
    WebLabel2.Left := 207;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 23;
    WebLabel2.Width := 82;
    btAnamnese.SetParentComponent(Self);
    btAnamnese.Name := 'btAnamnese';
    btAnamnese.Caption := 'Anamnèse';
    btAnamnese.Color := clNone;
    btAnamnese.Default := False;
    btAnamnese.Font.Charset := ANSI_CHARSET;
    btAnamnese.Font.Color := clBlack;
    btAnamnese.Font.Height := -18;
    btAnamnese.Font.Name := 'Arial';
    btAnamnese.Font.Size := 14;
    btAnamnese.Font.Style := [];
    btAnamnese.Height := 48;
    btAnamnese.Left := 548;
    SetEvent(btAnamnese, Self, 'OnClick', 'btAnamneseClick');
    btAnamnese.ParentFont := False;
    btAnamnese.Role := 'button';
    btAnamnese.TabOrder := 0;
    btAnamnese.Top := 55;
    btAnamnese.Width := 152;
    btMedicaments.SetParentComponent(Self);
    btMedicaments.Name := 'btMedicaments';
    btMedicaments.Caption := 'Médicaments';
    btMedicaments.Color := clNone;
    btMedicaments.Default := False;
    btMedicaments.Font.Charset := ANSI_CHARSET;
    btMedicaments.Font.Color := clBlack;
    btMedicaments.Font.Height := -18;
    btMedicaments.Font.Name := 'Arial';
    btMedicaments.Font.Size := 14;
    btMedicaments.Font.Style := [];
    btMedicaments.Height := 48;
    btMedicaments.Left := 708;
    SetEvent(btMedicaments, Self, 'OnClick', 'btMedicamentsClick');
    btMedicaments.ParentFont := False;
    btMedicaments.Role := 'button';
    btMedicaments.TabOrder := 0;
    btMedicaments.Top := 55;
    btMedicaments.Width := 152;
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Caption := 'Prestations';
    WebButton4.Color := clHotLight;
    WebButton4.Default := False;
    WebButton4.Font.Charset := ANSI_CHARSET;
    WebButton4.Font.Color := clWhite;
    WebButton4.Font.Height := -18;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Size := 14;
    WebButton4.Font.Style := [];
    WebButton4.Height := 48;
    WebButton4.Left := 868;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton4.ParentFont := False;
    WebButton4.Role := 'button';
    WebButton4.TabOrder := 0;
    WebButton4.Top := 55;
    WebButton4.Width := 152;
    WebListBox1.SetParentComponent(Self);
    WebListBox1.Name := 'WebListBox1';
    WebListBox1.Color := clWindow;
    WebListBox1.Font.Charset := ANSI_CHARSET;
    WebListBox1.Font.Color := clBlack;
    WebListBox1.Font.Height := -11;
    WebListBox1.Font.Name := 'Arial';
    WebListBox1.Font.Size := 8;
    WebListBox1.Font.Style := [];
    WebListBox1.Height := 336;
    WebListBox1.ItemHeight := 0;
    WebListBox1.Left := 15;
    WebListBox1.MultiSelect := False;
    SetEvent(WebListBox1, Self, 'OnChange', 'WebListBox1Change');
    WebListBox1.Role := 'listbox';
    WebListBox1.ShowFocus := True;
    WebListBox1.TabOrder := 0;
    WebListBox1.Top := 55;
    WebListBox1.Width := 184;
    WebListBox2.SetParentComponent(Self);
    WebListBox2.Name := 'WebListBox2';
    WebListBox2.Color := clWindow;
    WebListBox2.Font.Charset := ANSI_CHARSET;
    WebListBox2.Font.Color := clBlack;
    WebListBox2.Font.Height := -11;
    WebListBox2.Font.Name := 'Arial';
    WebListBox2.Font.Size := 8;
    WebListBox2.Font.Style := [];
    WebListBox2.Height := 336;
    WebListBox2.ItemHeight := 0;
    WebListBox2.Left := 207;
    WebListBox2.MultiSelect := False;
    WebListBox2.Role := 'listbox';
    WebListBox2.ShowFocus := True;
    WebListBox2.TabOrder := 0;
    WebListBox2.Top := 55;
    WebListBox2.Width := 216;
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Caption := 'Ajouter / supprimer des patients';
    WebButton5.Color := clNone;
    WebButton5.Default := False;
    WebButton5.Font.Charset := ANSI_CHARSET;
    WebButton5.Font.Color := clBlack;
    WebButton5.Font.Height := -11;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Size := 8;
    WebButton5.Font.Style := [];
    WebButton5.Height := 24;
    WebButton5.Left := 207;
    WebButton5.Role := 'button';
    WebButton5.TabOrder := 0;
    WebButton5.Top := 399;
    WebButton5.Width := 216;
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Caption := 'Monter';
    WebButton7.Color := clNone;
    WebButton7.Default := False;
    WebButton7.Font.Charset := ANSI_CHARSET;
    WebButton7.Font.Color := clBlack;
    WebButton7.Font.Height := -11;
    WebButton7.Font.Name := 'Arial';
    WebButton7.Font.Size := 8;
    WebButton7.Font.Style := [];
    WebButton7.Height := 24;
    WebButton7.Left := 432;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton7.Role := 'button';
    WebButton7.TabOrder := 0;
    WebButton7.Top := 179;
    WebButton7.Width := 64;
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Caption := 'Descendre';
    WebButton8.Color := clNone;
    WebButton8.Default := False;
    WebButton8.Font.Charset := ANSI_CHARSET;
    WebButton8.Font.Color := clBlack;
    WebButton8.Font.Height := -11;
    WebButton8.Font.Name := 'Arial';
    WebButton8.Font.Size := 8;
    WebButton8.Font.Style := [];
    WebButton8.Height := 24;
    WebButton8.Left := 432;
    WebButton8.Role := 'button';
    WebButton8.TabOrder := 0;
    WebButton8.Top := 210;
    WebButton8.Width := 64;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    btAnamnese.AfterLoadDFMValues;
    btMedicaments.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebListBox1.AfterLoadDFMValues;
    WebListBox2.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
  end;
end;

end.                   
unit Ufrmdiabete;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmDiabete = class(TForm)
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebEdit5: TEdit;
    WebCheckBox1: TCheckBox;
    WebCheckBox2: TCheckBox;
    WebCheckBox3: TCheckBox;
    WebCheckBox4: TCheckBox;
    WebLabel10: TLabel;
    WebEdit6: TEdit;
    WebEdit7: TEdit;
    WebLabel11: TLabel;
    WebLabel12: TLabel;
    WebEdit8: TEdit;
    WebLabel6: TLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebLabel3Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmDiabete: TfrmDiabete;

implementation

{$R *.dfm}




procedure TfrmDiabete.WebButton1Click(Sender: TObject);
begin
  close;
end;

procedure TfrmDiabete.WebLabel3Click(Sender: TObject);
begin
  close; 
end;

procedure TfrmDiabete.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebCheckBox2 := TCheckBox.Create(Self);
  WebCheckBox3 := TCheckBox.Create(Self);
  WebCheckBox4 := TCheckBox.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebEdit7 := TEdit.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebCheckBox2.BeforeLoadDFMValues;
  WebCheckBox3.BeforeLoadDFMValues;
  WebCheckBox4.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebEdit7.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'frmDiabete';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 24;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 75;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 45;
    WebEdit1.Width := 104;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 25;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 75;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 80;
    WebEdit2.Width := 100;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Alignment := taLeftJustify;
    WebEdit3.Color := clWindow;
    WebEdit3.Font.Charset := ANSI_CHARSET;
    WebEdit3.Font.Color := clBlack;
    WebEdit3.Font.Height := -11;
    WebEdit3.Font.Name := 'Arial';
    WebEdit3.Font.Size := 8;
    WebEdit3.Font.Style := [];
    WebEdit3.Height := 25;
    WebEdit3.HideSelection := False;
    WebEdit3.Left := 75;
    WebEdit3.ShowFocus := True;
    WebEdit3.TabOrder := 0;
    WebEdit3.Top := 112;
    WebEdit3.Width := 100;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Alignment := taLeftJustify;
    WebEdit4.Color := clWindow;
    WebEdit4.Font.Charset := ANSI_CHARSET;
    WebEdit4.Font.Color := clBlack;
    WebEdit4.Font.Height := -11;
    WebEdit4.Font.Name := 'Arial';
    WebEdit4.Font.Size := 8;
    WebEdit4.Font.Style := [];
    WebEdit4.Height := 25;
    WebEdit4.HideSelection := False;
    WebEdit4.Left := 75;
    WebEdit4.ShowFocus := True;
    WebEdit4.TabOrder := 0;
    WebEdit4.Top := 144;
    WebEdit4.Width := 100;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Alignment := taLeftJustify;
    WebEdit5.Color := clWindow;
    WebEdit5.Font.Charset := ANSI_CHARSET;
    WebEdit5.Font.Color := clBlack;
    WebEdit5.Font.Height := -11;
    WebEdit5.Font.Name := 'Arial';
    WebEdit5.Font.Size := 8;
    WebEdit5.Font.Style := [];
    WebEdit5.Height := 25;
    WebEdit5.HideSelection := False;
    WebEdit5.Left := 75;
    WebEdit5.ShowFocus := True;
    WebEdit5.TabOrder := 0;
    WebEdit5.Top := 176;
    WebEdit5.Width := 100;
    WebCheckBox1.SetParentComponent(Self);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Caption := 'Inspection des pieds';
    WebCheckBox1.Font.Charset := ANSI_CHARSET;
    WebCheckBox1.Font.Color := clBlack;
    WebCheckBox1.Font.Height := -11;
    WebCheckBox1.Font.Name := 'Arial';
    WebCheckBox1.Font.Size := 8;
    WebCheckBox1.Font.Style := [];
    WebCheckBox1.Height := 24;
    WebCheckBox1.Left := 21;
    WebCheckBox1.ShowFocus := True;
    WebCheckBox1.State := cbUnchecked;
    WebCheckBox1.TabOrder := 0;
    WebCheckBox1.Top := 203;
    WebCheckBox1.Width := 224;
    WebCheckBox2.SetParentComponent(Self);
    WebCheckBox2.Name := 'WebCheckBox2';
    WebCheckBox2.Caption := 'Conseils hygiène des pieds';
    WebCheckBox2.Font.Charset := ANSI_CHARSET;
    WebCheckBox2.Font.Color := clBlack;
    WebCheckBox2.Font.Height := -11;
    WebCheckBox2.Font.Name := 'Arial';
    WebCheckBox2.Font.Size := 8;
    WebCheckBox2.Font.Style := [];
    WebCheckBox2.Height := 24;
    WebCheckBox2.Left := 21;
    WebCheckBox2.ShowFocus := True;
    WebCheckBox2.State := cbUnchecked;
    WebCheckBox2.TabOrder := 0;
    WebCheckBox2.Top := 227;
    WebCheckBox2.Width := 224;
    WebCheckBox3.SetParentComponent(Self);
    WebCheckBox3.Name := 'WebCheckBox3';
    WebCheckBox3.Caption := 'Conseils diététiques';
    WebCheckBox3.Font.Charset := ANSI_CHARSET;
    WebCheckBox3.Font.Color := clBlack;
    WebCheckBox3.Font.Height := -11;
    WebCheckBox3.Font.Name := 'Arial';
    WebCheckBox3.Font.Size := 8;
    WebCheckBox3.Font.Style := [];
    WebCheckBox3.Height := 24;
    WebCheckBox3.Left := 21;
    WebCheckBox3.ShowFocus := True;
    WebCheckBox3.State := cbUnchecked;
    WebCheckBox3.TabOrder := 0;
    WebCheckBox3.Top := 251;
    WebCheckBox3.Width := 224;
    WebCheckBox4.SetParentComponent(Self);
    WebCheckBox4.Name := 'WebCheckBox4';
    WebCheckBox4.Caption := 'Conseils Conseils glycémie / injection';
    WebCheckBox4.Font.Charset := ANSI_CHARSET;
    WebCheckBox4.Font.Color := clBlack;
    WebCheckBox4.Font.Height := -11;
    WebCheckBox4.Font.Name := 'Arial';
    WebCheckBox4.Font.Size := 8;
    WebCheckBox4.Font.Style := [];
    WebCheckBox4.Height := 24;
    WebCheckBox4.Left := 21;
    WebCheckBox4.ShowFocus := True;
    WebCheckBox4.State := cbUnchecked;
    WebCheckBox4.TabOrder := 0;
    WebCheckBox4.Top := 275;
    WebCheckBox4.Width := 224;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Caption := 'Remarque';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := clBlack;
    WebLabel10.Font.Height := -20;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Size := 15;
    WebLabel10.Font.Style := [];
    WebLabel10.Height := 23;
    WebLabel10.Left := 19;
    WebLabel10.ParentFont := False;
    WebLabel10.Top := 307;
    WebLabel10.Width := 93;
    WebEdit6.SetParentComponent(Self);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Alignment := taLeftJustify;
    WebEdit6.Color := clWindow;
    WebEdit6.Font.Charset := ANSI_CHARSET;
    WebEdit6.Font.Color := clBlack;
    WebEdit6.Font.Height := -11;
    WebEdit6.Font.Name := 'Arial';
    WebEdit6.Font.Size := 8;
    WebEdit6.Font.Style := [];
    WebEdit6.Height := 64;
    WebEdit6.HideSelection := False;
    WebEdit6.Left := 128;
    WebEdit6.ShowFocus := True;
    WebEdit6.TabOrder := 0;
    WebEdit6.Top := 307;
    WebEdit6.Width := 464;
    WebEdit7.SetParentComponent(Self);
    WebEdit7.Name := 'WebEdit7';
    WebEdit7.Alignment := taLeftJustify;
    WebEdit7.Color := clWindow;
    WebEdit7.Font.Charset := ANSI_CHARSET;
    WebEdit7.Font.Color := clBlack;
    WebEdit7.Font.Height := -11;
    WebEdit7.Font.Name := 'Arial';
    WebEdit7.Font.Size := 8;
    WebEdit7.Font.Style := [];
    WebEdit7.Height := 64;
    WebEdit7.HideSelection := False;
    WebEdit7.Left := 128;
    WebEdit7.ShowFocus := True;
    WebEdit7.TabOrder := 0;
    WebEdit7.Top := 381;
    WebEdit7.Width := 464;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Caption := 'Action';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := clBlack;
    WebLabel11.Font.Height := -20;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Size := 15;
    WebLabel11.Font.Style := [];
    WebLabel11.Height := 23;
    WebLabel11.Left := 19;
    WebLabel11.ParentFont := False;
    WebLabel11.Top := 381;
    WebLabel11.Width := 56;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Caption := 'Résultat';
    WebLabel12.Font.Charset := ANSI_CHARSET;
    WebLabel12.Font.Color := clBlack;
    WebLabel12.Font.Height := -20;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Size := 15;
    WebLabel12.Font.Style := [];
    WebLabel12.Height := 23;
    WebLabel12.Left := 19;
    WebLabel12.ParentFont := False;
    WebLabel12.Top := 461;
    WebLabel12.Width := 73;
    WebEdit8.SetParentComponent(Self);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Alignment := taLeftJustify;
    WebEdit8.Color := clWindow;
    WebEdit8.Font.Charset := ANSI_CHARSET;
    WebEdit8.Font.Color := clBlack;
    WebEdit8.Font.Height := -11;
    WebEdit8.Font.Name := 'Arial';
    WebEdit8.Font.Size := 8;
    WebEdit8.Font.Style := [];
    WebEdit8.Height := 64;
    WebEdit8.HideSelection := False;
    WebEdit8.Left := 128;
    WebEdit8.ShowFocus := True;
    WebEdit8.TabOrder := 0;
    WebEdit8.Top := 448;
    WebEdit8.Width := 464;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Caption := 'Date';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := clBlack;
    WebLabel6.Font.Height := -20;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Size := 15;
    WebLabel6.Font.Style := [];
    WebLabel6.Height := 23;
    WebLabel6.Left := 19;
    WebLabel6.ParentFont := False;
    WebLabel6.Top := 45;
    WebLabel6.Width := 42;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := '8h';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -20;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 15;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 23;
    WebLabel1.Left := 32;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 81;
    WebLabel1.Width := 22;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := '12h';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -20;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 15;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 23;
    WebLabel2.Left := 21;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 114;
    WebLabel2.Width := 33;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Caption := '17h';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -20;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Size := 15;
    WebLabel3.Font.Style := [];
    WebLabel3.Height := 23;
    WebLabel3.Left := 21;
    WebLabel3.ParentFont := False;
    WebLabel3.Top := 146;
    WebLabel3.Width := 33;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Caption := '22h';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -20;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Size := 15;
    WebLabel4.Font.Style := [];
    WebLabel4.Height := 23;
    WebLabel4.Left := 21;
    WebLabel4.ParentFont := False;
    WebLabel4.Top := 178;
    WebLabel4.Width := 33;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := 'Signaler problème';
    WebButton2.Color := clRed;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 24;
    WebButton2.Left := 147;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 7;
    WebButton2.Width := 144;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Fermer';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 35;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 8;
    WebButton1.Width := 100;
  finally
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebCheckBox2.AfterLoadDFMValues;
    WebCheckBox3.AfterLoadDFMValues;
    WebCheckBox4.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebEdit7.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.   
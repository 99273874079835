unit Ufrmlogin;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls,Uodoo,Ufrmmain,WEBLib.JSON;

type
  TfrmLogin = class(TForm)
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebButton1: TButton;
    [async]
    procedure WebButton1Click(Sender: TObject); async;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmLogin: TfrmLogin;

implementation

{$R *.dfm}

procedure TfrmLogin.WebButton1Click(Sender: TObject);
var
frmMain : TfrmMain;
tournees:TJSONArray;
itournee:integer;
tournee:TJSONObject;


begin
  frmMain := TfrmMain.CreateNew;
  Odoo.odoologin:='jm.abe@soluceinfo.be';
  Odoo.odoopassword:='GtMsInfi47';
  Odoo.odooserver:='https://odoo.docinfi.be';
  Odoo.odoodatabase:='docinfi';
  await(Odoo.odooconnect);  
  tournees:=await(odoo.odooread('docinfi.tournee',''));
  for itournee := 0 to tournees.Count - 1 do
    begin
      tournee := tournees.Items[itournee] as TJSONObject;
      frmMain.WebListBox1.Items.AddObject(tournee.Get('name').JsonValue.Value, tlistid.Create(strtoint(tournee.Get('id').JsonValue.Value)));
    end;



  frmMain.Show;
  
  
  
end;



procedure TfrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'frmLogin';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Nom d'#39'utilisateur:';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 8;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 12;
    WebLabel1.Left := 23;
    WebLabel1.Top := 32;
    WebLabel1.Width := 82;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Mot de passe:';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -11;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 8;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 12;
    WebLabel2.Left := 23;
    WebLabel2.Top := 72;
    WebLabel2.Width := 67;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 24;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 115;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 32;
    WebEdit1.Width := 312;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 24;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 115;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 72;
    WebEdit2.Width := 312;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Connexion';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 327;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 119;
    WebButton1.Width := 100;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.  
unit Unit6;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.Grids;

type
  TForm6 = class(TForm)
    WebLabel1: TLabel;
    WebStringGrid1: TStringGrid;
    WebButton1: TButton;
    procedure Form6Create(Sender: TObject);

  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form6: TForm6;

implementation

uses
Ufrmprestationsmois;

{$R *.dfm}



procedure TForm6.Form6Create(Sender: TObject);
begin
WebStringGrid1.ColWidths[0]:=WebStringGrid1.width div 2;
WebStringGrid1.ColWidths[1]:=WebStringGrid1.width div 2;
WebStringGrid1.cells[0,0]:='Problème';
 WebStringGrid1.cells[1,0]:='Solution';  
end;

procedure TForm6.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Form6';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'Form6Create');
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Feuille cible';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -24;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 18;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 27;
    WebLabel1.Left := 334;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 36;
    WebLabel1.Width := 127;
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.ColCount := 2;
    WebStringGrid1.DefaultRowHeight := 22;
    WebStringGrid1.Font.Charset := ANSI_CHARSET;
    WebStringGrid1.Font.Color := clBlack;
    WebStringGrid1.Font.Height := -11;
    WebStringGrid1.Font.Name := 'Arial';
    WebStringGrid1.Font.Size := 8;
    WebStringGrid1.Font.Style := [];
    WebStringGrid1.Height := 304;
    WebStringGrid1.Left := 60;
    WebStringGrid1.Options := [goVertLine,goHorzLine,goRangeSelect];
    WebStringGrid1.RowCount := 5;
    WebStringGrid1.StyleElements := [];
    WebStringGrid1.TabOrder := 0;
    WebStringGrid1.Top := 102;
    WebStringGrid1.Width := 568;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Prestations';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 40;
    WebButton1.Left := 60;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 419;
    WebButton1.Width := 184;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end. 
unit Unit8;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TForm8 = class(TForm)
    WebLabel1: TLabel;
    WebListBox1: TListBox;
    WebListBox2: TListBox;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebButton3: TButton;
    procedure WebButton3Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form8: TForm8;

implementation

{$R *.dfm}

procedure TForm8.WebButton3Click(Sender: TObject);
begin
close;  
end;


procedure TForm8.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebListBox1 := TListBox.Create(Self);
  WebListBox2 := TListBox.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebButton3 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebListBox1.BeforeLoadDFMValues;
  WebListBox2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  try
    Name := 'Form8';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Liste des soins';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 24;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 36;
    WebLabel1.Left := 232;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 26;
    WebLabel1.Width := 212;
    WebListBox1.SetParentComponent(Self);
    WebListBox1.Name := 'WebListBox1';
    WebListBox1.Color := clWindow;
    WebListBox1.Font.Charset := ANSI_CHARSET;
    WebListBox1.Font.Color := clBlack;
    WebListBox1.Font.Height := -11;
    WebListBox1.Font.Name := 'Arial';
    WebListBox1.Font.Size := 8;
    WebListBox1.Font.Style := [];
    WebListBox1.Height := 320;
    WebListBox1.ItemHeight := 0;
    WebListBox1.Items.BeginUpdate;
    try
      WebListBox1.Items.Clear;
      WebListBox1.Items.Add('Diabétique');
      WebListBox1.Items.Add('Pansements');
    finally
      WebListBox1.Items.EndUpdate;
    end;
    WebListBox1.Left := 60;
    WebListBox1.MultiSelect := False;
    WebListBox1.ShowFocus := True;
    WebListBox1.TabOrder := 0;
    WebListBox1.Top := 116;
    WebListBox1.Width := 232;
    WebListBox2.SetParentComponent(Self);
    WebListBox2.Name := 'WebListBox2';
    WebListBox2.Color := clWindow;
    WebListBox2.Font.Charset := ANSI_CHARSET;
    WebListBox2.Font.Color := clBlack;
    WebListBox2.Font.Height := -11;
    WebListBox2.Font.Name := 'Arial';
    WebListBox2.Font.Size := 8;
    WebListBox2.Font.Style := [];
    WebListBox2.Height := 320;
    WebListBox2.ItemHeight := 0;
    WebListBox2.Left := 366;
    WebListBox2.MultiSelect := False;
    WebListBox2.Role := 'listbox';
    WebListBox2.ShowFocus := True;
    WebListBox2.TabOrder := 0;
    WebListBox2.Top := 116;
    WebListBox2.Width := 232;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := '<-';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 24;
    WebButton1.Left := 304;
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 231;
    WebButton1.Width := 52;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := '->';
    WebButton2.Color := clNone;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 24;
    WebButton2.Left := 304;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 274;
    WebButton2.Width := 52;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Liste globale';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -11;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 8;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 16;
    WebLabel2.Left := 60;
    WebLabel2.Top := 88;
    WebLabel2.Width := 64;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Caption := 'Sélectionné pour ce patient';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -11;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Size := 8;
    WebLabel3.Font.Style := [];
    WebLabel3.Height := 16;
    WebLabel3.Left := 366;
    WebLabel3.Top := 88;
    WebLabel3.Width := 128;
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Caption := 'Fermer';
    WebButton3.Color := clNone;
    WebButton3.Default := False;
    WebButton3.Font.Charset := ANSI_CHARSET;
    WebButton3.Font.Color := clBlack;
    WebButton3.Font.Height := -11;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Size := 8;
    WebButton3.Font.Style := [];
    WebButton3.Height := 24;
    WebButton3.Left := 20;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton3.Role := 'button';
    WebButton3.TabOrder := 0;
    WebButton3.Top := 12;
    WebButton3.Width := 104;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebListBox1.AfterLoadDFMValues;
    WebListBox2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
  end;
end;

end.
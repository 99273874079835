unit Ufrmsintrom;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmSintrom = class(TForm)
    WebEdit1: TEdit;
    WebLabel10: TLabel;
    WebEdit2: TEdit;
    WebLabel11: TLabel;
    WebLabel12: TLabel;
    WebEdit8: TEdit;
    WebButton2: TButton;
    WebEdit3: TEdit;
    WebLabel3: TLabel;
    WebEdit4: TEdit;
    WebLabel1: TLabel;
    WebEdit5: TEdit;
    WebLabel2: TLabel;
    WebButton3: TButton;
    procedure WebButton3Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmSintrom: TfrmSintrom;

implementation

{$R *.dfm}

procedure TfrmSintrom.WebButton3Click(Sender: TObject);
begin
  close; 
end;


procedure TfrmSintrom.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebEdit1 := TEdit.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton3 := TButton.Create(Self);

  WebEdit1.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  try
    Name := 'frmSintrom';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 64;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 144;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 179;
    WebEdit1.Width := 464;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Caption := 'Remarque';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := clBlack;
    WebLabel10.Font.Height := -20;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Size := 15;
    WebLabel10.Font.Style := [];
    WebLabel10.Height := 23;
    WebLabel10.Left := 35;
    WebLabel10.ParentFont := False;
    WebLabel10.Top := 179;
    WebLabel10.Width := 93;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 64;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 144;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 253;
    WebEdit2.Width := 464;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Caption := 'Action';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := clBlack;
    WebLabel11.Font.Height := -20;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Size := 15;
    WebLabel11.Font.Style := [];
    WebLabel11.Height := 23;
    WebLabel11.Left := 35;
    WebLabel11.ParentFont := False;
    WebLabel11.Top := 253;
    WebLabel11.Width := 56;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Caption := 'Résultat';
    WebLabel12.Font.Charset := ANSI_CHARSET;
    WebLabel12.Font.Color := clBlack;
    WebLabel12.Font.Height := -20;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Size := 15;
    WebLabel12.Font.Style := [];
    WebLabel12.Height := 23;
    WebLabel12.Left := 35;
    WebLabel12.ParentFont := False;
    WebLabel12.Top := 333;
    WebLabel12.Width := 73;
    WebEdit8.SetParentComponent(Self);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Alignment := taLeftJustify;
    WebEdit8.Color := clWindow;
    WebEdit8.Font.Charset := ANSI_CHARSET;
    WebEdit8.Font.Color := clBlack;
    WebEdit8.Font.Height := -11;
    WebEdit8.Font.Name := 'Arial';
    WebEdit8.Font.Size := 8;
    WebEdit8.Font.Style := [];
    WebEdit8.Height := 64;
    WebEdit8.HideSelection := False;
    WebEdit8.Left := 144;
    WebEdit8.ShowFocus := True;
    WebEdit8.TabOrder := 0;
    WebEdit8.Top := 320;
    WebEdit8.Width := 464;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := 'Signaler problème';
    WebButton2.Color := clRed;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 24;
    WebButton2.Left := 144;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 9;
    WebButton2.Width := 144;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Alignment := taLeftJustify;
    WebEdit3.Color := clWindow;
    WebEdit3.Font.Charset := ANSI_CHARSET;
    WebEdit3.Font.Color := clBlack;
    WebEdit3.Font.Height := -11;
    WebEdit3.Font.Name := 'Arial';
    WebEdit3.Font.Size := 8;
    WebEdit3.Font.Style := [];
    WebEdit3.Height := 25;
    WebEdit3.HideSelection := False;
    WebEdit3.Left := 248;
    WebEdit3.ShowFocus := True;
    WebEdit3.TabOrder := 0;
    WebEdit3.Top := 99;
    WebEdit3.Width := 100;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Caption := 'Posologie Sintrom';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -20;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Size := 15;
    WebLabel3.Font.Style := [];
    WebLabel3.Height := 23;
    WebLabel3.Left := 35;
    WebLabel3.ParentFont := False;
    WebLabel3.Top := 100;
    WebLabel3.Width := 162;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Alignment := taLeftJustify;
    WebEdit4.Color := clWindow;
    WebEdit4.Font.Charset := ANSI_CHARSET;
    WebEdit4.Font.Color := clBlack;
    WebEdit4.Font.Height := -11;
    WebEdit4.Font.Name := 'Arial';
    WebEdit4.Font.Size := 8;
    WebEdit4.Font.Style := [];
    WebEdit4.Height := 25;
    WebEdit4.HideSelection := False;
    WebEdit4.Left := 248;
    WebEdit4.ShowFocus := True;
    WebEdit4.TabOrder := 0;
    WebEdit4.Top := 60;
    WebEdit4.Width := 100;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Contrôle prise de sang';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -20;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 15;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 23;
    WebLabel1.Left := 35;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 60;
    WebLabel1.Width := 201;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Alignment := taLeftJustify;
    WebEdit5.Color := clWindow;
    WebEdit5.Font.Charset := ANSI_CHARSET;
    WebEdit5.Font.Color := clBlack;
    WebEdit5.Font.Height := -11;
    WebEdit5.Font.Name := 'Arial';
    WebEdit5.Font.Size := 8;
    WebEdit5.Font.Style := [];
    WebEdit5.Height := 25;
    WebEdit5.HideSelection := False;
    WebEdit5.Left := 248;
    WebEdit5.ShowFocus := True;
    WebEdit5.TabOrder := 0;
    WebEdit5.Top := 139;
    WebEdit5.Width := 100;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Prise du Sintrom';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -20;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 15;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 23;
    WebLabel2.Left := 35;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 140;
    WebLabel2.Width := 148;
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Caption := 'Fermer';
    WebButton3.Color := clNone;
    WebButton3.Default := False;
    WebButton3.Font.Charset := ANSI_CHARSET;
    WebButton3.Font.Color := clBlack;
    WebButton3.Font.Height := -11;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Size := 8;
    WebButton3.Font.Style := [];
    WebButton3.Height := 25;
    WebButton3.Left := 35;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton3.Role := 'button';
    WebButton3.TabOrder := 0;
    WebButton3.Top := 8;
    WebButton3.Width := 100;
  finally
    WebEdit1.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
  end;
end;

end.  
unit Uodoo;


interface
uses System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, WEBLib.TMSFNCUtils,Generics.Collections,WEBLib.JSON, Contnrs,
  WEBLib.REST,udm1;
type

  TListid = class
    id: integer;
  public
    constructor Create (pid: Integer);
  end;

TOdoo = class(TObject)
private
public
  odoologin : string;
odoopassword: string;
odooserver:string;
odoodatabase:string;
odoouid:string;

procedure RechargeTout;
 [async]
procedure odooconnect; async;
 [async]
function odooread(objodoo:string;odoodomain:string):TJSONArray; async;

end;

var
Odoo : TOdoo ;
dm1: tdm1;

implementation

constructor TListid.Create (pid: Integer);
begin
  id:=pid;
end;

procedure TOdoo.RechargeTout;
begin
 //  console.log('coucou');
end;

function TOdoo.odooread(objodoo:string;odoodomain:string):TJSONArray;
var
jsonusers: String; 

request: TJSXMLHttpRequest;

json: string; 
  javascriptobject: JSValue;  
  j, jo, jo2, ja, ligne, res, JSONObject: TJSONValue;
  I,I2,i3 : integer;
 stritem:string;
 strligne:string;
 jresult:string;
 jrecords:TJSONArray;
 jrecord:TJSONObject;
 jresp:TJSONObject;
 name:string;
begin
jsonusers:= '{"jsonrpc":"2.0","method":"call","params":{"service":"object","method":"execute","args":["'+odoodatabase+'",'+odoouid+',"'+odoopassword+'","'+objodoo+'","search_read",['+odoodomain+']]}}';
dm1.httpconnect.PostData:=jsonusers;
request := await ( TJSXMLHttpRequest, dm1.httpconnect.Perform() );   
json := string(request.response);

j := TTMSFNCUtils.ParseJSON(json);

res:=TTMSFNCUtils.GetJSONValue(j,'result');
if Assigned(j) and (res is TJSONArray) then
begin
  result:=res as TJSONArray;
  
  end;
  
end;

procedure TOdoo.odooconnect;
var
jsonlogin: String;
request: TJSXMLHttpRequest;

json: string; 
  javascriptobject: JSValue;  
  j, jo, jo2, ja, ligne, res, JSONObject: TJSONValue;
  I,I2,i3 : integer;
 stritem:string;
 strligne:string;
  resp:string;
 //resdict:TDictionary<TKey, TValue>;
 uids: TJSONArray;
 uid: TJSONObject;
begin
jsonlogin:='{"jsonrpc":"2.0","method":"call","params":{"service":"common","method":"login","args":["'+odoodatabase+'","' + odoologin + '","' + odoopassword + '"]}}';

dm1.httpconnect.URL:=odooserver+'/jsonrpc'; 
dm1.httpconnect.Headers.AddPair('content-type', 'application/json');
dm1.httpconnect.PostData:=jsonlogin;
request := await ( TJSXMLHttpRequest, dm1.httpconnect.Perform() ); 
resp:=string(request.response);
uid:=TJSONObject.ParseJSONValue(resp) as TJsonObject;
odoouid := uid.Get('result').JsonValue.Value;


end;

// on peut voir ça comme un evenement OnCreate de l'unité "Shared" et non pas FonctionsBDD
Initialization
Odoo := TOdoo.Create;
dm1 := Tdm1.Create;




end.
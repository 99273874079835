program Docinfi;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Ufrmmain in 'Ufrmmain.pas'{*.html},
  Ufrmanamnese in 'Ufrmanamnese.pas'{*.html},
  Ufrmmedicaments in 'Ufrmmedicaments.pas'{*.html},
  Ufrmcible in 'Ufrmcible.pas'{*.html},
  Ufrmprestationsmois in 'Ufrmprestationsmois.pas'{*.html},
  Unit6 in 'Unit6.pas'{*.html},
  UAjoutSupprPatients in 'UAjoutSupprPatients.pas'{*.html},
  Ufrmlogin in 'Ufrmlogin.pas'{*.html},
  Uodoo in 'Uodoo.pas',
  Udm1 in 'Udm1.pas',
  Unit5 in 'Unit5.pas',
  Unit7 in 'Unit7.pas'{*.html},
  Unit8 in 'Unit8.pas'{*.html},
  Unit9 in 'Unit9.pas'{*.html},
  Ufrmhydratation in 'Ufrmhydratation.pas'{*.html},

  Ufrmdiabete in 'Ufrmdiabete.pas'{*.html},
  Ufrmkatz in 'Ufrmkatz.pas'{*.html},
  Ufrmsintrom in 'Ufrmsintrom.pas'{*.html},
  Ufrmparamvitaux in 'Ufrmparamvitaux.pas'{*.html},

  Ufrmselles in 'Ufrmselles.pas'{*.html};


{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(Tfrmlogin, frmlogin);
  Application.Run;
end.
unit Ufrmparamvitaux;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmParamvitaux = class(TForm)
    WebEdit3: TEdit;
    WebLabel3: TLabel;
    WebEdit4: TEdit;
    WebLabel1: TLabel;
    WebEdit5: TEdit;
    WebLabel2: TLabel;
    WebEdit1: TEdit;
    WebLabel4: TLabel;
    WebEdit2: TEdit;
    WebLabel5: TLabel;
    WebEdit6: TEdit;
    WebLabel6: TLabel;
    WebEdit7: TEdit;
    WebLabel7: TLabel;
    WebEdit8: TEdit;
    WebEdit9: TEdit;
    WebEdit10: TEdit;
    WebEdit11: TEdit;
    WebEdit12: TEdit;
    WebEdit13: TEdit;
    WebEdit14: TEdit;
    WebEdit15: TEdit;
    WebEdit16: TEdit;
    WebEdit17: TEdit;
    WebEdit18: TEdit;
    WebEdit19: TEdit;
    WebEdit20: TEdit;
    WebEdit21: TEdit;
    WebEdit22: TEdit;
    WebEdit23: TEdit;
    WebEdit24: TEdit;
    WebEdit25: TEdit;
    WebEdit26: TEdit;
    WebEdit27: TEdit;
    WebEdit28: TEdit;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebButton2: TButton;
    WebLabel12: TLabel;
    WebEdit29: TEdit;
    WebLabel13: TLabel;
    WebEdit30: TEdit;
    WebLabel14: TLabel;
    WebEdit31: TEdit;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmParamvitaux: TfrmParamvitaux;

implementation

{$R *.dfm}

procedure TfrmParamvitaux.WebButton1Click(Sender: TObject);
begin
close;  
end;


procedure TfrmParamvitaux.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebEdit3 := TEdit.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebEdit7 := TEdit.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebEdit9 := TEdit.Create(Self);
  WebEdit10 := TEdit.Create(Self);
  WebEdit11 := TEdit.Create(Self);
  WebEdit12 := TEdit.Create(Self);
  WebEdit13 := TEdit.Create(Self);
  WebEdit14 := TEdit.Create(Self);
  WebEdit15 := TEdit.Create(Self);
  WebEdit16 := TEdit.Create(Self);
  WebEdit17 := TEdit.Create(Self);
  WebEdit18 := TEdit.Create(Self);
  WebEdit19 := TEdit.Create(Self);
  WebEdit20 := TEdit.Create(Self);
  WebEdit21 := TEdit.Create(Self);
  WebEdit22 := TEdit.Create(Self);
  WebEdit23 := TEdit.Create(Self);
  WebEdit24 := TEdit.Create(Self);
  WebEdit25 := TEdit.Create(Self);
  WebEdit26 := TEdit.Create(Self);
  WebEdit27 := TEdit.Create(Self);
  WebEdit28 := TEdit.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebEdit29 := TEdit.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebEdit30 := TEdit.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebEdit31 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebEdit3.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebEdit7.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebEdit9.BeforeLoadDFMValues;
  WebEdit10.BeforeLoadDFMValues;
  WebEdit11.BeforeLoadDFMValues;
  WebEdit12.BeforeLoadDFMValues;
  WebEdit13.BeforeLoadDFMValues;
  WebEdit14.BeforeLoadDFMValues;
  WebEdit15.BeforeLoadDFMValues;
  WebEdit16.BeforeLoadDFMValues;
  WebEdit17.BeforeLoadDFMValues;
  WebEdit18.BeforeLoadDFMValues;
  WebEdit19.BeforeLoadDFMValues;
  WebEdit20.BeforeLoadDFMValues;
  WebEdit21.BeforeLoadDFMValues;
  WebEdit22.BeforeLoadDFMValues;
  WebEdit23.BeforeLoadDFMValues;
  WebEdit24.BeforeLoadDFMValues;
  WebEdit25.BeforeLoadDFMValues;
  WebEdit26.BeforeLoadDFMValues;
  WebEdit27.BeforeLoadDFMValues;
  WebEdit28.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebEdit29.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebEdit30.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebEdit31.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'frmParamvitaux';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 610;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 874;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Alignment := taLeftJustify;
    WebEdit3.Color := clWindow;
    WebEdit3.Font.Charset := ANSI_CHARSET;
    WebEdit3.Font.Color := clBlack;
    WebEdit3.Font.Height := -11;
    WebEdit3.Font.Name := 'Arial';
    WebEdit3.Font.Size := 8;
    WebEdit3.Font.Style := [];
    WebEdit3.Height := 25;
    WebEdit3.HideSelection := False;
    WebEdit3.Left := 248;
    WebEdit3.ShowFocus := True;
    WebEdit3.TabOrder := 0;
    WebEdit3.Top := 107;
    WebEdit3.Width := 100;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Caption := 'Pulsation (bat/min)';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -20;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Size := 15;
    WebLabel3.Font.Style := [];
    WebLabel3.Height := 23;
    WebLabel3.Left := 35;
    WebLabel3.ParentFont := False;
    WebLabel3.Top := 108;
    WebLabel3.Width := 167;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Alignment := taLeftJustify;
    WebEdit4.Color := clWindow;
    WebEdit4.Font.Charset := ANSI_CHARSET;
    WebEdit4.Font.Color := clBlack;
    WebEdit4.Font.Height := -11;
    WebEdit4.Font.Name := 'Arial';
    WebEdit4.Font.Size := 8;
    WebEdit4.Font.Style := [];
    WebEdit4.Height := 25;
    WebEdit4.HideSelection := False;
    WebEdit4.Left := 248;
    WebEdit4.ShowFocus := True;
    WebEdit4.TabOrder := 0;
    WebEdit4.Top := 68;
    WebEdit4.Width := 100;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'TA (cmhg)';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -20;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 15;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 23;
    WebLabel1.Left := 35;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 68;
    WebLabel1.Width := 91;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Alignment := taLeftJustify;
    WebEdit5.Color := clWindow;
    WebEdit5.Font.Charset := ANSI_CHARSET;
    WebEdit5.Font.Color := clBlack;
    WebEdit5.Font.Height := -11;
    WebEdit5.Font.Name := 'Arial';
    WebEdit5.Font.Size := 8;
    WebEdit5.Font.Style := [];
    WebEdit5.Height := 25;
    WebEdit5.HideSelection := False;
    WebEdit5.Left := 248;
    WebEdit5.ShowFocus := True;
    WebEdit5.TabOrder := 0;
    WebEdit5.Top := 147;
    WebEdit5.Width := 100;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Saturation (%)';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -20;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 15;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 23;
    WebLabel2.Left := 35;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 148;
    WebLabel2.Width := 128;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 25;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 248;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 227;
    WebEdit1.Width := 100;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Caption := 'Poids (Kg)';
    WebLabel4.Font.Charset := ANSI_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -20;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Size := 15;
    WebLabel4.Font.Style := [];
    WebLabel4.Height := 23;
    WebLabel4.Left := 35;
    WebLabel4.ParentFont := False;
    WebLabel4.Top := 228;
    WebLabel4.Width := 93;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 25;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 248;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 188;
    WebEdit2.Width := 100;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Caption := 'Température (°C)';
    WebLabel5.Font.Charset := ANSI_CHARSET;
    WebLabel5.Font.Color := clBlack;
    WebLabel5.Font.Height := -20;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Size := 15;
    WebLabel5.Font.Style := [];
    WebLabel5.Height := 23;
    WebLabel5.Left := 35;
    WebLabel5.ParentFont := False;
    WebLabel5.Top := 188;
    WebLabel5.Width := 154;
    WebEdit6.SetParentComponent(Self);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Alignment := taLeftJustify;
    WebEdit6.Color := clWindow;
    WebEdit6.Font.Charset := ANSI_CHARSET;
    WebEdit6.Font.Color := clBlack;
    WebEdit6.Font.Height := -11;
    WebEdit6.Font.Name := 'Arial';
    WebEdit6.Font.Size := 8;
    WebEdit6.Font.Style := [];
    WebEdit6.Height := 25;
    WebEdit6.HideSelection := False;
    WebEdit6.Left := 248;
    WebEdit6.ShowFocus := True;
    WebEdit6.TabOrder := 0;
    WebEdit6.Top := 267;
    WebEdit6.Width := 100;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Caption := 'Taille (cm)';
    WebLabel6.Font.Charset := ANSI_CHARSET;
    WebLabel6.Font.Color := clBlack;
    WebLabel6.Font.Height := -20;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Size := 15;
    WebLabel6.Font.Style := [];
    WebLabel6.Height := 23;
    WebLabel6.Left := 35;
    WebLabel6.ParentFont := False;
    WebLabel6.Top := 268;
    WebLabel6.Width := 91;
    WebEdit7.SetParentComponent(Self);
    WebEdit7.Name := 'WebEdit7';
    WebEdit7.Alignment := taLeftJustify;
    WebEdit7.Color := clWindow;
    WebEdit7.Font.Charset := ANSI_CHARSET;
    WebEdit7.Font.Color := clBlack;
    WebEdit7.Font.Height := -11;
    WebEdit7.Font.Name := 'Arial';
    WebEdit7.Font.Size := 8;
    WebEdit7.Font.Style := [];
    WebEdit7.Height := 25;
    WebEdit7.HideSelection := False;
    WebEdit7.Left := 248;
    WebEdit7.ShowFocus := True;
    WebEdit7.TabOrder := 0;
    WebEdit7.Top := 307;
    WebEdit7.Width := 100;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Caption := 'Douleur (1-10)';
    WebLabel7.Font.Charset := ANSI_CHARSET;
    WebLabel7.Font.Color := clBlack;
    WebLabel7.Font.Height := -20;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Size := 15;
    WebLabel7.Font.Style := [];
    WebLabel7.Height := 23;
    WebLabel7.Left := 35;
    WebLabel7.ParentFont := False;
    WebLabel7.Top := 308;
    WebLabel7.Width := 129;
    WebEdit8.SetParentComponent(Self);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Alignment := taLeftJustify;
    WebEdit8.Color := clWindow;
    WebEdit8.Font.Charset := ANSI_CHARSET;
    WebEdit8.Font.Color := clBlack;
    WebEdit8.Font.Height := -11;
    WebEdit8.Font.Name := 'Arial';
    WebEdit8.Font.Size := 8;
    WebEdit8.Font.Style := [];
    WebEdit8.Height := 25;
    WebEdit8.HideSelection := False;
    WebEdit8.Left := 360;
    WebEdit8.ShowFocus := True;
    WebEdit8.TabOrder := 0;
    WebEdit8.Top := 107;
    WebEdit8.Width := 100;
    WebEdit9.SetParentComponent(Self);
    WebEdit9.Name := 'WebEdit9';
    WebEdit9.Alignment := taLeftJustify;
    WebEdit9.Color := clWindow;
    WebEdit9.Font.Charset := ANSI_CHARSET;
    WebEdit9.Font.Color := clBlack;
    WebEdit9.Font.Height := -11;
    WebEdit9.Font.Name := 'Arial';
    WebEdit9.Font.Size := 8;
    WebEdit9.Font.Style := [];
    WebEdit9.Height := 25;
    WebEdit9.HideSelection := False;
    WebEdit9.Left := 360;
    WebEdit9.ShowFocus := True;
    WebEdit9.TabOrder := 0;
    WebEdit9.Top := 68;
    WebEdit9.Width := 100;
    WebEdit10.SetParentComponent(Self);
    WebEdit10.Name := 'WebEdit10';
    WebEdit10.Alignment := taLeftJustify;
    WebEdit10.Color := clWindow;
    WebEdit10.Font.Charset := ANSI_CHARSET;
    WebEdit10.Font.Color := clBlack;
    WebEdit10.Font.Height := -11;
    WebEdit10.Font.Name := 'Arial';
    WebEdit10.Font.Size := 8;
    WebEdit10.Font.Style := [];
    WebEdit10.Height := 25;
    WebEdit10.HideSelection := False;
    WebEdit10.Left := 360;
    WebEdit10.ShowFocus := True;
    WebEdit10.TabOrder := 0;
    WebEdit10.Top := 147;
    WebEdit10.Width := 100;
    WebEdit11.SetParentComponent(Self);
    WebEdit11.Name := 'WebEdit11';
    WebEdit11.Alignment := taLeftJustify;
    WebEdit11.Color := clWindow;
    WebEdit11.Font.Charset := ANSI_CHARSET;
    WebEdit11.Font.Color := clBlack;
    WebEdit11.Font.Height := -11;
    WebEdit11.Font.Name := 'Arial';
    WebEdit11.Font.Size := 8;
    WebEdit11.Font.Style := [];
    WebEdit11.Height := 25;
    WebEdit11.HideSelection := False;
    WebEdit11.Left := 360;
    WebEdit11.ShowFocus := True;
    WebEdit11.TabOrder := 0;
    WebEdit11.Top := 227;
    WebEdit11.Width := 100;
    WebEdit12.SetParentComponent(Self);
    WebEdit12.Name := 'WebEdit12';
    WebEdit12.Alignment := taLeftJustify;
    WebEdit12.Color := clWindow;
    WebEdit12.Font.Charset := ANSI_CHARSET;
    WebEdit12.Font.Color := clBlack;
    WebEdit12.Font.Height := -11;
    WebEdit12.Font.Name := 'Arial';
    WebEdit12.Font.Size := 8;
    WebEdit12.Font.Style := [];
    WebEdit12.Height := 25;
    WebEdit12.HideSelection := False;
    WebEdit12.Left := 360;
    WebEdit12.ShowFocus := True;
    WebEdit12.TabOrder := 0;
    WebEdit12.Top := 188;
    WebEdit12.Width := 100;
    WebEdit13.SetParentComponent(Self);
    WebEdit13.Name := 'WebEdit13';
    WebEdit13.Alignment := taLeftJustify;
    WebEdit13.Color := clWindow;
    WebEdit13.Font.Charset := ANSI_CHARSET;
    WebEdit13.Font.Color := clBlack;
    WebEdit13.Font.Height := -11;
    WebEdit13.Font.Name := 'Arial';
    WebEdit13.Font.Size := 8;
    WebEdit13.Font.Style := [];
    WebEdit13.Height := 25;
    WebEdit13.HideSelection := False;
    WebEdit13.Left := 360;
    WebEdit13.ShowFocus := True;
    WebEdit13.TabOrder := 0;
    WebEdit13.Top := 267;
    WebEdit13.Width := 100;
    WebEdit14.SetParentComponent(Self);
    WebEdit14.Name := 'WebEdit14';
    WebEdit14.Alignment := taLeftJustify;
    WebEdit14.Color := clWindow;
    WebEdit14.Font.Charset := ANSI_CHARSET;
    WebEdit14.Font.Color := clBlack;
    WebEdit14.Font.Height := -11;
    WebEdit14.Font.Name := 'Arial';
    WebEdit14.Font.Size := 8;
    WebEdit14.Font.Style := [];
    WebEdit14.Height := 25;
    WebEdit14.HideSelection := False;
    WebEdit14.Left := 360;
    WebEdit14.ShowFocus := True;
    WebEdit14.TabOrder := 0;
    WebEdit14.Top := 307;
    WebEdit14.Width := 100;
    WebEdit15.SetParentComponent(Self);
    WebEdit15.Name := 'WebEdit15';
    WebEdit15.Alignment := taLeftJustify;
    WebEdit15.Color := clWindow;
    WebEdit15.Font.Charset := ANSI_CHARSET;
    WebEdit15.Font.Color := clBlack;
    WebEdit15.Font.Height := -11;
    WebEdit15.Font.Name := 'Arial';
    WebEdit15.Font.Size := 8;
    WebEdit15.Font.Style := [];
    WebEdit15.Height := 25;
    WebEdit15.HideSelection := False;
    WebEdit15.Left := 472;
    WebEdit15.ShowFocus := True;
    WebEdit15.TabOrder := 0;
    WebEdit15.Top := 68;
    WebEdit15.Width := 100;
    WebEdit16.SetParentComponent(Self);
    WebEdit16.Name := 'WebEdit16';
    WebEdit16.Alignment := taLeftJustify;
    WebEdit16.Color := clWindow;
    WebEdit16.Font.Charset := ANSI_CHARSET;
    WebEdit16.Font.Color := clBlack;
    WebEdit16.Font.Height := -11;
    WebEdit16.Font.Name := 'Arial';
    WebEdit16.Font.Size := 8;
    WebEdit16.Font.Style := [];
    WebEdit16.Height := 25;
    WebEdit16.HideSelection := False;
    WebEdit16.Left := 472;
    WebEdit16.ShowFocus := True;
    WebEdit16.TabOrder := 0;
    WebEdit16.Top := 107;
    WebEdit16.Width := 100;
    WebEdit17.SetParentComponent(Self);
    WebEdit17.Name := 'WebEdit17';
    WebEdit17.Alignment := taLeftJustify;
    WebEdit17.Color := clWindow;
    WebEdit17.Font.Charset := ANSI_CHARSET;
    WebEdit17.Font.Color := clBlack;
    WebEdit17.Font.Height := -11;
    WebEdit17.Font.Name := 'Arial';
    WebEdit17.Font.Size := 8;
    WebEdit17.Font.Style := [];
    WebEdit17.Height := 25;
    WebEdit17.HideSelection := False;
    WebEdit17.Left := 472;
    WebEdit17.ShowFocus := True;
    WebEdit17.TabOrder := 0;
    WebEdit17.Top := 147;
    WebEdit17.Width := 100;
    WebEdit18.SetParentComponent(Self);
    WebEdit18.Name := 'WebEdit18';
    WebEdit18.Alignment := taLeftJustify;
    WebEdit18.Color := clWindow;
    WebEdit18.Font.Charset := ANSI_CHARSET;
    WebEdit18.Font.Color := clBlack;
    WebEdit18.Font.Height := -11;
    WebEdit18.Font.Name := 'Arial';
    WebEdit18.Font.Size := 8;
    WebEdit18.Font.Style := [];
    WebEdit18.Height := 25;
    WebEdit18.HideSelection := False;
    WebEdit18.Left := 472;
    WebEdit18.ShowFocus := True;
    WebEdit18.TabOrder := 0;
    WebEdit18.Top := 227;
    WebEdit18.Width := 100;
    WebEdit19.SetParentComponent(Self);
    WebEdit19.Name := 'WebEdit19';
    WebEdit19.Alignment := taLeftJustify;
    WebEdit19.Color := clWindow;
    WebEdit19.Font.Charset := ANSI_CHARSET;
    WebEdit19.Font.Color := clBlack;
    WebEdit19.Font.Height := -11;
    WebEdit19.Font.Name := 'Arial';
    WebEdit19.Font.Size := 8;
    WebEdit19.Font.Style := [];
    WebEdit19.Height := 25;
    WebEdit19.HideSelection := False;
    WebEdit19.Left := 472;
    WebEdit19.ShowFocus := True;
    WebEdit19.TabOrder := 0;
    WebEdit19.Top := 188;
    WebEdit19.Width := 100;
    WebEdit20.SetParentComponent(Self);
    WebEdit20.Name := 'WebEdit20';
    WebEdit20.Alignment := taLeftJustify;
    WebEdit20.Color := clWindow;
    WebEdit20.Font.Charset := ANSI_CHARSET;
    WebEdit20.Font.Color := clBlack;
    WebEdit20.Font.Height := -11;
    WebEdit20.Font.Name := 'Arial';
    WebEdit20.Font.Size := 8;
    WebEdit20.Font.Style := [];
    WebEdit20.Height := 25;
    WebEdit20.HideSelection := False;
    WebEdit20.Left := 472;
    WebEdit20.ShowFocus := True;
    WebEdit20.TabOrder := 0;
    WebEdit20.Top := 267;
    WebEdit20.Width := 100;
    WebEdit21.SetParentComponent(Self);
    WebEdit21.Name := 'WebEdit21';
    WebEdit21.Alignment := taLeftJustify;
    WebEdit21.Color := clWindow;
    WebEdit21.Font.Charset := ANSI_CHARSET;
    WebEdit21.Font.Color := clBlack;
    WebEdit21.Font.Height := -11;
    WebEdit21.Font.Name := 'Arial';
    WebEdit21.Font.Size := 8;
    WebEdit21.Font.Style := [];
    WebEdit21.Height := 25;
    WebEdit21.HideSelection := False;
    WebEdit21.Left := 472;
    WebEdit21.ShowFocus := True;
    WebEdit21.TabOrder := 0;
    WebEdit21.Top := 307;
    WebEdit21.Width := 100;
    WebEdit22.SetParentComponent(Self);
    WebEdit22.Name := 'WebEdit22';
    WebEdit22.Alignment := taLeftJustify;
    WebEdit22.Color := clWindow;
    WebEdit22.Font.Charset := ANSI_CHARSET;
    WebEdit22.Font.Color := clBlack;
    WebEdit22.Font.Height := -11;
    WebEdit22.Font.Name := 'Arial';
    WebEdit22.Font.Size := 8;
    WebEdit22.Font.Style := [];
    WebEdit22.Height := 25;
    WebEdit22.HideSelection := False;
    WebEdit22.Left := 584;
    WebEdit22.ShowFocus := True;
    WebEdit22.TabOrder := 0;
    WebEdit22.Top := 68;
    WebEdit22.Width := 100;
    WebEdit23.SetParentComponent(Self);
    WebEdit23.Name := 'WebEdit23';
    WebEdit23.Alignment := taLeftJustify;
    WebEdit23.Color := clWindow;
    WebEdit23.Font.Charset := ANSI_CHARSET;
    WebEdit23.Font.Color := clBlack;
    WebEdit23.Font.Height := -11;
    WebEdit23.Font.Name := 'Arial';
    WebEdit23.Font.Size := 8;
    WebEdit23.Font.Style := [];
    WebEdit23.Height := 25;
    WebEdit23.HideSelection := False;
    WebEdit23.Left := 584;
    WebEdit23.ShowFocus := True;
    WebEdit23.TabOrder := 0;
    WebEdit23.Top := 107;
    WebEdit23.Width := 100;
    WebEdit24.SetParentComponent(Self);
    WebEdit24.Name := 'WebEdit24';
    WebEdit24.Alignment := taLeftJustify;
    WebEdit24.Color := clWindow;
    WebEdit24.Font.Charset := ANSI_CHARSET;
    WebEdit24.Font.Color := clBlack;
    WebEdit24.Font.Height := -11;
    WebEdit24.Font.Name := 'Arial';
    WebEdit24.Font.Size := 8;
    WebEdit24.Font.Style := [];
    WebEdit24.Height := 25;
    WebEdit24.HideSelection := False;
    WebEdit24.Left := 584;
    WebEdit24.ShowFocus := True;
    WebEdit24.TabOrder := 0;
    WebEdit24.Top := 147;
    WebEdit24.Width := 100;
    WebEdit25.SetParentComponent(Self);
    WebEdit25.Name := 'WebEdit25';
    WebEdit25.Alignment := taLeftJustify;
    WebEdit25.Color := clWindow;
    WebEdit25.Font.Charset := ANSI_CHARSET;
    WebEdit25.Font.Color := clBlack;
    WebEdit25.Font.Height := -11;
    WebEdit25.Font.Name := 'Arial';
    WebEdit25.Font.Size := 8;
    WebEdit25.Font.Style := [];
    WebEdit25.Height := 25;
    WebEdit25.HideSelection := False;
    WebEdit25.Left := 584;
    WebEdit25.ShowFocus := True;
    WebEdit25.TabOrder := 0;
    WebEdit25.Top := 227;
    WebEdit25.Width := 100;
    WebEdit26.SetParentComponent(Self);
    WebEdit26.Name := 'WebEdit26';
    WebEdit26.Alignment := taLeftJustify;
    WebEdit26.Color := clWindow;
    WebEdit26.Font.Charset := ANSI_CHARSET;
    WebEdit26.Font.Color := clBlack;
    WebEdit26.Font.Height := -11;
    WebEdit26.Font.Name := 'Arial';
    WebEdit26.Font.Size := 8;
    WebEdit26.Font.Style := [];
    WebEdit26.Height := 25;
    WebEdit26.HideSelection := False;
    WebEdit26.Left := 584;
    WebEdit26.ShowFocus := True;
    WebEdit26.TabOrder := 0;
    WebEdit26.Top := 188;
    WebEdit26.Width := 100;
    WebEdit27.SetParentComponent(Self);
    WebEdit27.Name := 'WebEdit27';
    WebEdit27.Alignment := taLeftJustify;
    WebEdit27.Color := clWindow;
    WebEdit27.Font.Charset := ANSI_CHARSET;
    WebEdit27.Font.Color := clBlack;
    WebEdit27.Font.Height := -11;
    WebEdit27.Font.Name := 'Arial';
    WebEdit27.Font.Size := 8;
    WebEdit27.Font.Style := [];
    WebEdit27.Height := 25;
    WebEdit27.HideSelection := False;
    WebEdit27.Left := 584;
    WebEdit27.ShowFocus := True;
    WebEdit27.TabOrder := 0;
    WebEdit27.Top := 267;
    WebEdit27.Width := 100;
    WebEdit28.SetParentComponent(Self);
    WebEdit28.Name := 'WebEdit28';
    WebEdit28.Alignment := taLeftJustify;
    WebEdit28.Color := clWindow;
    WebEdit28.Font.Charset := ANSI_CHARSET;
    WebEdit28.Font.Color := clBlack;
    WebEdit28.Font.Height := -11;
    WebEdit28.Font.Name := 'Arial';
    WebEdit28.Font.Size := 8;
    WebEdit28.Font.Style := [];
    WebEdit28.Height := 25;
    WebEdit28.HideSelection := False;
    WebEdit28.Left := 584;
    WebEdit28.ShowFocus := True;
    WebEdit28.TabOrder := 0;
    WebEdit28.Top := 307;
    WebEdit28.Width := 100;
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Caption := '8h';
    WebLabel8.Font.Charset := ANSI_CHARSET;
    WebLabel8.Font.Color := clBlack;
    WebLabel8.Font.Height := -20;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Size := 15;
    WebLabel8.Font.Style := [];
    WebLabel8.Height := 23;
    WebLabel8.Left := 281;
    WebLabel8.ParentFont := False;
    WebLabel8.Top := 36;
    WebLabel8.Width := 22;
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Caption := '12h';
    WebLabel9.Font.Charset := ANSI_CHARSET;
    WebLabel9.Font.Color := clBlack;
    WebLabel9.Font.Height := -20;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Size := 15;
    WebLabel9.Font.Style := [];
    WebLabel9.Height := 23;
    WebLabel9.Left := 385;
    WebLabel9.ParentFont := False;
    WebLabel9.Top := 36;
    WebLabel9.Width := 33;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Caption := '18h';
    WebLabel10.Font.Charset := ANSI_CHARSET;
    WebLabel10.Font.Color := clBlack;
    WebLabel10.Font.Height := -20;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Size := 15;
    WebLabel10.Font.Style := [];
    WebLabel10.Height := 23;
    WebLabel10.Left := 497;
    WebLabel10.ParentFont := False;
    WebLabel10.Top := 36;
    WebLabel10.Width := 33;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Caption := '20h';
    WebLabel11.Font.Charset := ANSI_CHARSET;
    WebLabel11.Font.Color := clBlack;
    WebLabel11.Font.Height := -20;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Size := 15;
    WebLabel11.Font.Style := [];
    WebLabel11.Height := 23;
    WebLabel11.Left := 617;
    WebLabel11.ParentFont := False;
    WebLabel11.Top := 28;
    WebLabel11.Width := 33;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := 'Signaler problème';
    WebButton2.Color := clRed;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 24;
    WebButton2.Left := 144;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 8;
    WebButton2.Width := 144;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Caption := 'Remarque';
    WebLabel12.Font.Charset := ANSI_CHARSET;
    WebLabel12.Font.Color := clBlack;
    WebLabel12.Font.Height := -20;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Size := 15;
    WebLabel12.Font.Style := [];
    WebLabel12.Height := 23;
    WebLabel12.Left := 35;
    WebLabel12.ParentFont := False;
    WebLabel12.Top := 347;
    WebLabel12.Width := 93;
    WebEdit29.SetParentComponent(Self);
    WebEdit29.Name := 'WebEdit29';
    WebEdit29.Alignment := taLeftJustify;
    WebEdit29.Color := clWindow;
    WebEdit29.Font.Charset := ANSI_CHARSET;
    WebEdit29.Font.Color := clBlack;
    WebEdit29.Font.Height := -11;
    WebEdit29.Font.Name := 'Arial';
    WebEdit29.Font.Size := 8;
    WebEdit29.Font.Style := [];
    WebEdit29.Height := 64;
    WebEdit29.HideSelection := False;
    WebEdit29.Left := 144;
    WebEdit29.ShowFocus := True;
    WebEdit29.TabOrder := 0;
    WebEdit29.Top := 347;
    WebEdit29.Width := 464;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Caption := 'Action';
    WebLabel13.Font.Charset := ANSI_CHARSET;
    WebLabel13.Font.Color := clBlack;
    WebLabel13.Font.Height := -20;
    WebLabel13.Font.Name := 'Arial';
    WebLabel13.Font.Size := 15;
    WebLabel13.Font.Style := [];
    WebLabel13.Height := 23;
    WebLabel13.Left := 35;
    WebLabel13.ParentFont := False;
    WebLabel13.Top := 421;
    WebLabel13.Width := 56;
    WebEdit30.SetParentComponent(Self);
    WebEdit30.Name := 'WebEdit30';
    WebEdit30.Alignment := taLeftJustify;
    WebEdit30.Color := clWindow;
    WebEdit30.Font.Charset := ANSI_CHARSET;
    WebEdit30.Font.Color := clBlack;
    WebEdit30.Font.Height := -11;
    WebEdit30.Font.Name := 'Arial';
    WebEdit30.Font.Size := 8;
    WebEdit30.Font.Style := [];
    WebEdit30.Height := 64;
    WebEdit30.HideSelection := False;
    WebEdit30.Left := 144;
    WebEdit30.ShowFocus := True;
    WebEdit30.TabOrder := 0;
    WebEdit30.Top := 421;
    WebEdit30.Width := 464;
    WebLabel14.SetParentComponent(Self);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Caption := 'Résultat';
    WebLabel14.Font.Charset := ANSI_CHARSET;
    WebLabel14.Font.Color := clBlack;
    WebLabel14.Font.Height := -20;
    WebLabel14.Font.Name := 'Arial';
    WebLabel14.Font.Size := 15;
    WebLabel14.Font.Style := [];
    WebLabel14.Height := 23;
    WebLabel14.Left := 35;
    WebLabel14.ParentFont := False;
    WebLabel14.Top := 501;
    WebLabel14.Width := 73;
    WebEdit31.SetParentComponent(Self);
    WebEdit31.Name := 'WebEdit31';
    WebEdit31.Alignment := taLeftJustify;
    WebEdit31.Color := clWindow;
    WebEdit31.Font.Charset := ANSI_CHARSET;
    WebEdit31.Font.Color := clBlack;
    WebEdit31.Font.Height := -11;
    WebEdit31.Font.Name := 'Arial';
    WebEdit31.Font.Size := 8;
    WebEdit31.Font.Style := [];
    WebEdit31.Height := 64;
    WebEdit31.HideSelection := False;
    WebEdit31.Left := 144;
    WebEdit31.ShowFocus := True;
    WebEdit31.TabOrder := 0;
    WebEdit31.Top := 488;
    WebEdit31.Width := 464;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Fermer';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 35;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 8;
    WebButton1.Width := 100;
  finally
    WebEdit3.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebEdit7.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebEdit9.AfterLoadDFMValues;
    WebEdit10.AfterLoadDFMValues;
    WebEdit11.AfterLoadDFMValues;
    WebEdit12.AfterLoadDFMValues;
    WebEdit13.AfterLoadDFMValues;
    WebEdit14.AfterLoadDFMValues;
    WebEdit15.AfterLoadDFMValues;
    WebEdit16.AfterLoadDFMValues;
    WebEdit17.AfterLoadDFMValues;
    WebEdit18.AfterLoadDFMValues;
    WebEdit19.AfterLoadDFMValues;
    WebEdit20.AfterLoadDFMValues;
    WebEdit21.AfterLoadDFMValues;
    WebEdit22.AfterLoadDFMValues;
    WebEdit23.AfterLoadDFMValues;
    WebEdit24.AfterLoadDFMValues;
    WebEdit25.AfterLoadDFMValues;
    WebEdit26.AfterLoadDFMValues;
    WebEdit27.AfterLoadDFMValues;
    WebEdit28.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebEdit29.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebEdit30.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebEdit31.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end. 
unit Ufrmprestationsmois;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.Grids, Ufrmhydratation,ufrmdiabete,ufrmkatz,ufrmselles,ufrmparamvitaux,ufrmsintrom,WEBLib.JSON;

type
  TfrmPrestationsmois = class(TForm)
    WebLabel1: TLabel;
    wsgPrest: TStringGrid;
    WebLabel2: TLabel;
     WebButton1: TButton;
     WebButton2: TButton;
     
   
    procedure Form5Create(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure wsgPrestClick(Sender: TObject);
  private
    { Private declarations }
  public
    prestations:TJSONArray;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmPrestationsmois: TfrmPrestationsmois;

implementation

{$R *.dfm}


procedure TfrmPrestationsmois.wsgPrestClick(Sender: TObject);
var
frmHydratation : TfrmHydratation;
frmparamvitaux : Tfrmparamvitaux;
frmsintrom : Tfrmsintrom;
frmdiabete : tfrmdiabete;
frmkatz : Tfrmkatz;
frmselles : Tfrmselles;
fenetreid : string;
prestation:TJSONObject;
begin
  prestation := prestations.Items[wsgPrest.row-1] as TJSONObject;
  fenetreid:=prestation.Get('fenetreid').JsonValue.Value;
  if fenetreid = 'hydratation' then
  begin
    frmHydratation:=TfrmHydratation.CreateNew;
    frmHydratation.showmodal();
  end;
  if fenetreid = 'diabete' then
  begin
    frmdiabete:=Tfrmdiabete.CreateNew;
    frmdiabete.showmodal();
  end;
  if fenetreid = 'sintrom' then
  begin
    frmsintrom:=Tfrmsintrom.CreateNew;
    frmsintrom.showmodal();
  end;
  if fenetreid = 'paramsvitaux' then
  begin
    frmparamvitaux:=Tfrmparamvitaux.CreateNew;
    frmparamvitaux.showmodal();
  end;
  if fenetreid = 'katz' then
  begin
    frmkatz:=Tfrmkatz.CreateNew;
    frmkatz.showmodal();
  end;
  if fenetreid = 'selles' then
  begin
    frmselles:=Tfrmselles.CreateNew;
    frmselles.showmodal();
  end;
  
 
end;

procedure TfrmPrestationsmois.WebButton1Click(Sender: TObject);
begin
close;  
end;

procedure TfrmPrestationsmois.Form5Create(Sender: TObject);
var
i: integer;
begin

wsgPrest.Width:=Integer(window.screen['width']) * 90 div 100;
wsgPrest.height:=Integer(window.screen['height']) * 70 div 100;
wsgPrest.ColWidths[0]:=(wsgPrest.width div 35) *7;
wsgPrest.cells[0,0]:='Nom';  
for i:=1 to 31 do
  begin
    wsgPrest.ColWidths[i]:=(wsgPrest.width div 40);
    wsgPrest.cells[i,0]:=str(i);  

  end;
end;



procedure TfrmPrestationsmois.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  wsgPrest := TStringGrid.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  wsgPrest.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'frmPrestationsmois';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 938;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'Form5Create');
    TabOrder := 0;
    Top := 0;
    Width := 1058;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Prestations du mois';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -26;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 20;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 31;
    WebLabel1.Left := 399;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 13;
    WebLabel1.Width := 234;
    wsgPrest.SetParentComponent(Self);
    wsgPrest.Name := 'wsgPrest';
    wsgPrest.ColCount := 32;
    wsgPrest.DefaultRowHeight := 22;
    wsgPrest.Font.Charset := ANSI_CHARSET;
    wsgPrest.Font.Color := clBlack;
    wsgPrest.Font.Height := -11;
    wsgPrest.Font.Name := 'Arial';
    wsgPrest.Font.Size := 8;
    wsgPrest.Font.Style := [];
    wsgPrest.Height := 832;
    wsgPrest.Left := 17;
    SetEvent(wsgPrest, Self, 'OnClick', 'wsgPrestClick');
    wsgPrest.Options := [goVertLine,goHorzLine,goRangeSelect];
    wsgPrest.RowCount := 5;
    wsgPrest.StyleElements := [];
    wsgPrest.TabOrder := 0;
    wsgPrest.Top := 79;
    wsgPrest.Width := 1024;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Patient:';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -18;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 14;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 22;
    WebLabel2.Left := 17;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 48;
    WebLabel2.Width := 63;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Fermer';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 17;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 13;
    WebButton1.Width := 100;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := 'Modifier liste';
    WebButton2.Color := clNone;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 24;
    WebButton2.Left := 133;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 13;
    WebButton2.Width := 136;
  finally
    WebLabel1.AfterLoadDFMValues;
    wsgPrest.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.      
unit Unit7;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmAnamnese2 = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebButton1: TButton;
    WebLabel3: TLabel;
    WebEdit2: TEdit;
    procedure WebButton1Click(Sender: TObject);
    procedure WebLabel3Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmAnamnese2: TfrmAnamnese2;

implementation

{$R *.dfm}

procedure TfrmAnamnese2.WebLabel3Click(Sender: TObject);
begin
  
end;

procedure TfrmAnamnese2.WebButton1Click(Sender: TObject);
begin
close;  
end;

procedure TfrmAnamnese2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'frmAnamnese2';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Anamnese';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 24;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 36;
    WebLabel1.Left := 310;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 23;
    WebLabel1.Width := 153;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 24;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 143;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 113;
    WebEdit1.Width := 168;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Nom:';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -11;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 8;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 12;
    WebLabel2.Left := 103;
    WebLabel2.Top := 113;
    WebLabel2.Width := 25;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'WebButton1';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 45;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 23;
    WebButton1.Width := 100;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end. 
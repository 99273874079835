unit Udm1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, WEBLib.REST;

type
  Tdm1 = class(TDataModule)
    httpconnect: THTTPRequest;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  dm1: Tdm1;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

procedure Tdm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  httpconnect := THTTPRequest.Create(Self);

  httpconnect.BeforeLoadDFMValues;
  try
    Name := 'dm1';
    Height := 480;
    Width := 640;
    httpconnect.SetParentComponent(Self);
    httpconnect.Name := 'httpconnect';
    httpconnect.Command := httpPOST;
    httpconnect.Left := 208;
    httpconnect.Top := 144;
  finally
    httpconnect.AfterLoadDFMValues;
  end;
end;

end.  
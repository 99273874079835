{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Buttons;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, WEBLib.Controls, Web, WEBLib.Graphics, WEBLib.StdCtrls,
  WEBlib.ComCtrls, WEBLib.ExtCtrls, WEBLib.Forms, WEBLib.Menus;

type

  TButtonLayout = (blGlyphLeft, blGlyphRight, blGlyphTop, blGlyphBottom);

  TCustomSpeedButton = class(TWebCustomControl)
  private
    FMaterialGlyph: TMaterialGlyph;
    FColor: TColor;
    FIntColor: TColor;
    FDown: boolean;
    FFlat: boolean;
    FGroupIndex: integer;
    FAllowAllUp: boolean;
    FGlyph: TURLPicture;
    FLayout: TButtonLayout;
    FGlyphURL: String;
    FMaterialGlyphSize: integer;
    FMaterialGlyphColor: TColor;
    FMaterialGlyphType: TMaterialGlyphType;
    FGlyphSize: integer;
    procedure SetMaterialGlyph(const AValue: TMaterialGlyph);
    procedure SetColorEx(const AValue: TColor);
    procedure SetIntColor(const AValue: TColor);
    procedure SetGroupIndex(const AValue: integer);
    procedure SetDown(const AValue: boolean);
    procedure SetFlat(const Value: boolean);
    procedure SetGlyph(const Value: TURLPicture);
    procedure SetGlyphURL(const Value: String);
    procedure SetMaterialGlyphSize(const Value: integer);
    procedure SetMaterialGyphColor(const Value: TColor);
    procedure SetMaterialGlyphType(const Value: TMaterialGlyphType);
    procedure SetGlyphSize(const Value: integer);
  protected
    procedure SetEnabled(Value: Boolean); override;
    procedure SetCaption(const AValue: string); override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateElementVisual; override;
    procedure UpdateGroup; virtual;
    procedure DoMouseLeave; override;
    procedure DoMouseEnter; override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure GlyphChanged(Sender: TObject);
    property AllowAllUp: boolean read FAllowAllUp write FAllowAllUp;
    property Color: TColor read FColor write SetColorEx default clNone;
    property IntColor: TColor read FIntColor write SetIntColor;
    property Down: boolean read FDown write SetDown;
    property Flat: boolean read FFlat write SetFlat default false;
    property Glyph: TURLPicture read FGlyph write SetGlyph;
    property GlyphURL: String read FGlyphURL write SetGlyphURL;
    property GlyphSize: integer read FGlyphSize write SetGlyphSize default 0;
    property GroupIndex: integer read FGroupIndex write SetGroupIndex;
    property Layout: TButtonLayout read FLayout write FLayout default blGlyphLeft;
    property MaterialGlyph: TMaterialGlyph read FMaterialGlyph write SetMaterialGlyph;
    property MaterialGlyphColor: TColor read FMaterialGlyphColor write SetMaterialGyphColor default clNone;
    property MaterialGlyphSize: integer read FMaterialGlyphSize write SetMaterialGlyphSize default 18;
    property MaterialGlyphType: TMaterialGlyphType read FMaterialGlyphType write SetMaterialGlyphType default mgNormal;

  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
    function CanFocus: Boolean; override;
  end;

  TSpeedButton = class(TCustomSpeedButton)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AllowAllUp;
    property Caption;
    property Color;
    property Down;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Flat;
    property Font;
    property Glyph;
    property GlyphURL;
    property GlyphSize;
    property GroupIndex;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Layout;
    property Margins;
    property MaterialGlyph;
    property MaterialGlyphColor;
    property MaterialGlyphSize;
    property MaterialGlyphType;
    property ParentFont;
    property PopupMenu;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;


  TWebSpeedButton = class(TSpeedButton);

  TCustomBitBtn = class(TCustomControl)
  private
    FMaterialGlyph: TMaterialGlyph;
    FLayout: TButtonLayout;
    FFlat: boolean;
    FGlyph: TURLPicture;
    FColor: TColor;
    FIntColor: TColor;
    FAlignment: TAlignment;
    FGlyphURL: String;
    FMaterialGlyphSize: integer;
    FMaterialGlyphColor: TColor;
    FMaterialGlyphType: TMaterialGlyphType;
    FGlyphSize: integer;
    procedure SetFlat(const Value: boolean);
    procedure SetGlyph(const Value: TURLPicture);
    procedure SetLayout(const Value: TButtonLayout);
    procedure SetMaterialGlyph(const Value: TMaterialGlyph);
    procedure SetColorEx(const Value: TColor);
    procedure SetIntColor(const Value: TColor);
    procedure SetAlignment(const Value: TAlignment);
    procedure SetGlyphURL(const Value: String);
    procedure SetMaterialGlyphSize(const Value: integer);
    procedure SetMaterialGyphColor(const Value: TColor);
    procedure SetMaterialGlyphType(const Value: TMaterialGlyphType);
    procedure SetGlyphSize(const Value: integer);
  protected
    procedure SetCaption(const AValue: string); override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateElementVisual; override;
    procedure GlyphChanged(Sender: TObject);
    property Alignment: TAlignment read FAlignment write SetAlignment default taCenter;
    property Color: TColor read FColor write SetColorEx;
    property IntColor: TColor read FIntColor write SetIntColor;
    property Flat: boolean read FFlat write SetFlat default false;
    property Layout: TButtonLayout read FLayout write SetLayout default blGlyphLeft;
    property MaterialGlyph: TMaterialGlyph read FMaterialGlyph write SetMaterialGlyph;
    property MaterialGlyphColor: TColor read FMaterialGlyphColor write SetMaterialGyphColor default clNone;
    property MaterialGlyphSize: integer read FMaterialGlyphSize write SetMaterialGlyphSize default 18;
    property MaterialGlyphType: TMaterialGlyphType read FMaterialGlyphType write SetMaterialGlyphType default mgNormal;
    property Glyph: TURLPicture read FGlyph write SetGlyph;
    property GlyphURL: String read FGlyphURL write SetGlyphURL;
    property GlyphSize: integer read FGlyphSize write SetGlyphSize default 0;
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
  end;

  TBitBtn = class(TCustomBitBtn)
  published
    property Align;
    property AlignWithMargins;
    property Alignment;
    property Anchors;
    property Caption;
    property Color;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Flat;
    property Glyph;
    property GlyphURL;
    property GlyphSize;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Layout;
    property MaterialGlyph;
    property MaterialGlyphColor;
    property MaterialGlyphSize;
    property MaterialGlyphType;
    property ParentFont;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebBitBtn = class(TBitBtn);

  TCustomToolBar = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  public
    procedure CreateInitialize; override;
    property Color default $CFCFCF;
  end;

  TToolBar = class(TCustomToolBar)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color;
    property DragMode;
    property Enabled;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property Margins;
    property ShowHint;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebToolBar = class(TToolBar);

  TRichEditBtn = (reFont, reFontSize, reBold, reItalic, reUnderline, reStrikeThrough,
    reAlignLeft, reAlignCenter, reAlignRight, reUnorderedList, reOrderedList, reForegroundColor, reBackgroundColor, reHyperlink, reImageInsert, reLineSpacing);

  TRichEditButtonSet = set of TRichEditBtn;

  TRichEditToolBar = class(TToolBar)
  private
    FBold: TSpeedButton;
    FItalic: TSpeedButton;
    FUnderline: TSpeedButton;
    FStrikeThrough: TSpeedButton;
    FAlignLeft: TSpeedButton;
    FAlignCenter: TSpeedButton;
    FAlignRight: TSpeedButton;
    FUList: TSpeedButton;
    FOList: TSpeedButton;
    FURL: TSpeedButton;
    FRichEdit: TRichEdit;
    FFnt: TFontPicker;
    FFntSize: TFontSizePicker;
    FFgClr: TWebColorPicker;
    FBkClr: TWebColorPicker;
    FImage: TSpeedButton;
    FLineSpacing: TLineSpacingPicker;
    FOnSpeedButtonClick: TNotifyEvent;
    FTextColor: TColor;
    FBackgroundColor: TColor;
    FVisibleButtons: TRichEditButtonSet;
    FHints: TStrings;
    FBtnID: string;
    FFilePicker: TJSHTMLElement;
    FPtrFileLoad: pointer;
    procedure SetRichEdit(const AValue: TRichEdit);
    function GetRichEdit: TRichEdit;
    procedure SetTextColor(const Value: TColor);
    procedure SetBackgroundColor(const Value: TColor);
    procedure SetVisibleButtons(const Value: TRichEditButtonSet);
    procedure SetHints(const Value: TStrings);
    function HandleDoFileLoad(Event: TEventListenerEvent): Boolean; virtual;
  protected
    procedure Notification(AComponent: TComponent;  Operation: TOperation); override;
    procedure CreateButton(var btn: TSpeedButton; BtnID, Glyph, Hint: string);
    procedure HandleSpeedButtonClick(Sender: TObject); virtual;
    procedure FgSelect(Sender: TObject);
    procedure BkSelect(Sender: TObject);
    procedure FntChange(Sender: TObject);
    procedure FntSizeChange(Sender: TObject);
    procedure LineSpacingChange(Sender: TObject);
    procedure UpdateButtons; virtual;
    procedure UpdateElement; override;
    procedure Loaded; override;
    procedure HandleAttributeChanged(Sender: TObject; Attributes: TSelAttributes);
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    function InitButtons(): boolean;
    procedure SetParent(AValue: TControl); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    property FontPicker: TFontPicker read FFnt;
    property FontSizePicker: TFontSizePicker read FFntSize;
    property BoldButton: TSpeedButton read FBold;
    property ItalicButton: TSpeedButton read FItalic;
    property UnderlineButton: TSpeedButton read FUnderline;
    property StrikeThroughButton: TSpeedButton read FStrikeThrough;
    property AlignLeftButton: TSpeedButton read FAlignLeft;
    property AlignCenterButton: TSpeedButton read FAlignCenter;
    property AlignRightButton: TSpeedButton read FAlignRight;
    property UListButton: TSpeedButton read FUList;
    property OListButton: TSpeedButton read FOList;
    property URLButton: TSpeedButton read FURL;
    property ImageButton: TSpeedButton read FImage;
    property ForegroundColorPicker: TWebColorPicker read FFgClr;
    property BackgroundColorPicker: TWebColorPicker read FBkClr;
    property LineSpacingPicker: TLineSpacingPicker read FLineSpacing;
  published
    property BackgroundColor: TColor read FBackgroundColor write SetBackgroundColor default clWhite;
    property Hints: TStrings read FHints write SetHints;
    property RichEdit: TRichEdit read GetRichEdit write SetRichEdit;
    property TextColor: TColor read FTextColor write SetTextColor default clBlack;
    property VisibleButtons: TRichEditButtonSet read FVisibleButtons write SetVisibleButtons;
    property OnSpeedButtonClick: TNotifyEvent read FOnSpeedButtonClick write FOnSpeedButtonClick;
  end;

  TWebRichEditToolBar = class(TRichEditToolBar);

  TToggleButtonStyle = (tsRectangular, tsRounded);

  TToggleButton = class(TCustomControl)
  private
    FChecked: boolean;
    FLabel: TJSHTMLElement;
    FInput: TJSHTMLElement;
    FSlider: TJSHTMLElement;
    FStyle: TToggleButtonStyle;
    procedure SetStyle(const Value: TToggleButtonStyle);
    function GetChecked: boolean;
    procedure SetChecked(const Value: boolean);
  protected
    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    procedure UpdateElement; override;
    function CreateElement: TJSElement; override;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
  published
    property Anchors;
    property Checked: boolean read GetChecked write SetChecked;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Style: TToggleButtonStyle read FStyle write SetStyle default tsRectangular;
    property Visible;
    property OnClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebToggleButton = class(TToggleButton);


implementation

uses
  WEBLib.WebTools, Math;

// https://stackoverflow.com/questions/1720320/how-to-dynamically-create-css-class-in-javascript-and-apply
// https://www.w3schools.com/howto/howto_css_switch.asp

type
  TRichEditorCracker = class(TRichEdit);

{ TCustomSpeedButton }

procedure TCustomSpeedButton.CreateInitialize;
begin
  inherited;
  FColor := clNone;
  FIntColor := clNone;
  FGroupIndex := 0;
  FDown := false;
  FFlat := false;
  FGlyph := TURLPicture.Create;
  FGlyph.OnChange := GlyphChanged;
  FGlyph.OnDataChange := GlyphChanged;
  FLayout := blGlyphLeft;
  FMaterialGlyphSize := 18;
  FMaterialGlyphColor := clNone;
  FMaterialGlyphType := mgNormal;
end;

function TCustomSpeedButton.CanFocus: Boolean;
begin
  Result := false;
end;

function TCustomSpeedButton.CreateElement: TJSElement;
begin
  if FFlat then
    Result := document.createElement('SPAN')
  else
    Result := document.createElement('BUTTON');
end;

procedure TCustomSpeedButton.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetColorEx(const AValue: TColor);
begin
  if FColor <> AValue then
  begin
    FColor := AValue;
    FIntColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetDown(const AValue: boolean);
begin
  if (FDown <> AValue) then
  begin
    FDown := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TCustomSpeedButton.SetFlat(const Value: boolean);
begin
  if (FFlat <> Value) then
  begin
    FFlat := Value;
    RecreateElement;
  end;
end;

procedure TCustomSpeedButton.SetIntColor(const AValue: TColor);
begin
  if FIntColor <> AValue then
  begin
    FIntColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetMaterialGlyph(const AValue: TMaterialGlyph);
begin
  if FMaterialGlyph <> AValue then
  begin
    FMaterialGlyph := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetMaterialGlyphSize(const Value: integer);
begin
  if (Value > 0) and (Value < 100) then
  begin
    FMaterialGlyphSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetMaterialGlyphType(const Value: TMaterialGlyphType);
begin
  if FMaterialGlyphType <> Value then
  begin
    FMaterialGlyphType := Value;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetMaterialGyphColor(const Value: TColor);
begin
  if (FMaterialGlyphColor <> Value) then
  begin
    FMaterialGlyphColor := Value;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetGlyph(const Value: TURLPicture);
begin
  FGlyph.Assign(Value);
  UpdateElement;
end;

procedure TCustomSpeedButton.SetGlyphSize(const Value: integer);
begin
  if (FGlyphSize <> Value) then
  begin
    FGlyphSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetGlyphURL(const Value: String);
begin
  if FGlyphURL <> Value then
  begin
    FGlyphURL := Value;
    RecreateElement;
  end;
end;

procedure TCustomSpeedButton.SetGroupIndex(const AValue: integer);
begin
  FGroupIndex := AValue;
end;

procedure TCustomSpeedButton.UpdateElement;
var
  s,inactive,noptr,icn,imgsrc,gs: string;
  matclr,cap,acc: string;
begin
  inherited;

  if not IsUpdating and (MaterialGlyph <> '') then
    AddControlLink('googlematerial', 'https://fonts.googleapis.com/icon?family=Material+Icons');

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('vertical-align','middle');

//    if Visible then
//      ElementHandle.style.setProperty('display','table');

    ElementHandle.style.setProperty('overflow','hidden');
    ElementHandle.style.setProperty('padding','0px');
    ElementHandle.style.setProperty('text-align','center');

    if FFlat and Visible then
    begin
      ElementHandle.style.setProperty('display','flex');
      ElementHandle.style.setProperty('justify-content','center');
    end;

    s := '';
    inactive := '';
    noptr := '';

    if not Enabled then
      inactive := ' md-light md-inactive';

    noptr := 'pointer-events:none';

    if FMaterialGlyph <> '' then
    begin
      matclr := '';
      if FMaterialGlyphColor <> clNone then
        matclr := ';color:'+ColorToHTML(FMaterialGlyphColor);

      icn := '<i class="' + GetMaterialClass(MaterialGlyphType) + inactive+'" style="'+noptr+';font-size:'+ IntToStr(FMaterialGlyphSize)+'px' + matclr+'">' + FMaterialGlyph + '</i>';
    end;

    if FGlyph.Data <> '' then
      imgsrc :=  'data:image/jpeg;base64,' + HexImageDecodeAsBase64(FGlyph.Data)
    else
    if FGlyph.FileName <> '' then
      imgsrc := FGlyph.FileName
    else
    if FGlyphURL <> '' then
      imgsrc := FGlyphURL;

    gs := '';
    if GlyphSize <> 0 then
      gs := 'width:'+GlyphSize.ToString+'px';

    if imgsrc <> '' then
      icn := '<img style="'+noptr+';vertical-align:middle;'+gs+'" src="'+ imgsrc + '">';

    if (ElementHandle.childElementCount = 0) or not IsLinked then
      cap := ProcessAccelerator(Caption,acc);

    if acc <> '' then
      ElementHandle['accesskey'] := acc;

    if TabStop then
      ElementHandle['tabindex'] := inttostr(TabOrder)
    else
      ElementHandle['tabindex'] := '-1';

    case Layout of
    blGlyphLeft: s := '<table border="0" align="center" style="'+noptr+'"><tr><td style="border:none">'+icn+'</td><td style="border:none">'+cap+'</td></tr></table>';
    blGlyphRight:  s := '<table border="0" align="center" style="'+noptr+'"><tr><td style="border:none">'+cap+'</td><td style="border:none">'+icn+'</td></tr></table>';
    blGlyphTop: s := '<table border="0" align="center" style="'+noptr+'"><tr><td style="border:none">'+icn+'</td></tr><tr><td style="border:none">'+cap+'</td></tr></table>';
    blGlyphBottom:  s := '<table border="0" align="center" style="'+noptr+'"><tr><td style="border:none">'+cap+'</td></tr><tr><td style="border:none">'+icn+'</td></tr></table>';
    end;

    ElementHandle.innerHTML := s;

    SetHTMLElementFont(TJSHTMLElement(ElementHandle.firstChild), Font, not ((ElementClassName = '') and (ElementFont = efProperty)) );

    if not IsEnabled then
      TJSHTMLElement(ElementHandle).style.removeProperty('color')
    else // restore color when font color is used
      if (ELementFont = efProperty) and (ElementClassName = '') then
      begin
        TJSHTMLElement(ElementHandle).style.setProperty('color',ColorToHTML(Font.Color));
        if (csDesigning in ComponentState) then
          TJSHTMLElement(ElementHandle.firstChild).style.setProperty('color',ColorToHTML(Font.Color));
      end;
  end;
end;

procedure TCustomSpeedButton.UpdateElementVisual;
var
  clr: TColor;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if Down then
      clr := clGray
    else
      clr := FIntColor;

    if (clr <> clNone) and (ElementClassName = '') then
      ElementHandle.style.setProperty('background-color', ColorToHTML(clr))
    else
      ElementHandle.style.setProperty('background-color', '');

    if Assigned(ElementHandle.firstChild) then
      SetHTMLElementFont(TJSHTMLElement(ElementHandle.firstChild), Font, not ((ElementClassName = '') and (ElementFont = efProperty)) );

    if not IsEnabled then
      TJSHTMLElement(ElementHandle).style.removeProperty('color')
    else // restore color when font color is used
      if (ELementFont = efProperty) and (ElementClassName = '') then
      begin
        TJSHTMLElement(ElementHandle).style.setProperty('color',ColorToHTML(Font.Color));
        if (csDesigning in ComponentState) and Assigned(ElementHandle.firstChild) then
          TJSHTMLElement(ElementHandle.firstChild).style.setProperty('color',ColorToHTML(Font.Color));
      end;
  end;
end;

procedure TCustomSpeedButton.UpdateGroup;
var
  i: integer;
begin
  if Assigned(Parent) then
  begin
    for i := 0 to Parent.ControlCount - 1 do
    begin
      if (Parent.Controls[i] <> Self) and (Parent.Controls[i] is TSpeedButton) and ((Parent.Controls[i] as TSpeedButton).GroupIndex = GroupIndex) then
        (Parent.Controls[i] as TSpeedButton).Down := false;
    end;
  end;
end;

procedure TCustomSpeedButton.DoMouseLeave;
var
  frm: TCustomForm;
begin
  inherited;
  frm := GetParentForm(Self);
  if Assigned(frm) and (frm.CSSLibrary = cssBootstrap) and ShowHint and (Hint <> '') then
    Exit;

  IntColor := FColor;
end;

procedure TCustomSpeedButton.GlyphChanged(Sender: TObject);
begin
  UpdateElement;
end;


destructor TCustomSpeedButton.Destroy;
begin
  FGlyph.Free;
  inherited;
end;

procedure TCustomSpeedButton.DoMouseEnter;
var
  frm: TCustomForm;
begin
  inherited;

  frm := GetParentForm(Self);
  if Assigned(frm) and (frm.CSSLibrary = cssBootstrap) and ShowHint and (Hint <> '') then
    Exit;
  if Enabled then
    IntColor := clSilver;
end;

procedure TCustomSpeedButton.MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  if Enabled then
    IntColor := clSilver;
end;

procedure TCustomSpeedButton.MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  if not Enabled then
    Exit;

  IntColor := clGray;

  if GroupIndex > 0 then
  begin
    if Down and AllowAllUp then
      Down := false
    else
    begin
      Down := true;
      UpdateGroup;
    end;
  end;
end;

{ TCustomToolBar }

function TCustomToolBar.CreateElement: TJSElement;
var
  el: TJSHTMLElement;
begin
  Result := document.createElement('SPAN');
  // add design-time layer to prevent interacting with the controls on the toolbar
  if (csDesigning in ComponentState) then
  begin
    el := TJSHTMLElement(document.createElement('DIV'));
    el.style.setProperty('z-index','10');
    el.style.setProperty('width','100%');
    el.style.setProperty('height','100%');
    el.style.setProperty('webkit-user-select', 'none');
    el.style.setProperty('moz-user-select', 'none');
    el.style.setProperty('khtml-user-select', 'none');
    el.style.setProperty('ms-user-select', 'none');
    el.style.setProperty('user-select', 'none');
    el.style.setProperty('position', 'absolute');

    Result.appendChild(el);
  end;
end;

procedure TCustomToolBar.CreateInitialize;
begin
  inherited;
  Color := $CFCFCF;
  ControlStyle := ControlStyle + [csAcceptsControls];
  Width := 300;
  Height := 32;
end;

{ TRichEditToolBar }

procedure TRichEditToolBar.CreateInitialize;

begin
  inherited;
  FPtrFileLoad := @HandleDoFileLoad;

  // do not permit other child controls
  ControlStyle := ControlStyle - [csAcceptsControls];

  FTextColor := clBlack;
  FBackgroundColor := clWhite;

  FBtnID := FindUniqueName('RETBBtn');

  FFnt := TFontPicker.Create(Self);
  FFnt.Name := FBtnID + '_fontname';
  FFnt.Parent := Self;
  FFnt.Width := 150;
  FFnt.Align := alLeft;
  FFnt.ItemIndex := 0;
  FFnt.OnChange := FntChange;

  FFntSize := TFontSizePicker.Create(Self);
  FFntSize.Name := FBtnID + '_fontsize';
  FFntSize.Parent := Self;
  FFntSize.Width := 50;
  FFntSize.Align := alLeft;
  FFntSize.PickerMode := fmRelativeSize;
  FFntSize.ItemIndex := 0;
  FFntSize.OnChange := FntSizeChange;

  FHints := TStringList.Create;
  FHints.Add('Bold');
  FHints.Add('Italic');
  FHints.Add('Underline');
  FHints.Add('Strike Throught');

  FHints.Add('Text Color');
  FHints.Add('Background Color');
  FHints.Add('Align left');
  FHints.Add('Align center');
  FHints.Add('Align right');
  FHints.Add('Numbered list');
  FHints.Add('List');
  FHints.Add('Hyperlink');
  FHints.Add('Insert image');
  FHints.Add('Line spacing');

  CreateButton(FBold, FBtnID + '_bold', '&#xE238;',FHints.Strings[0]);
  CreateButton(FItalic, FBtnID + '_italic', '&#xE23F;',FHints.Strings[1]);
  CreateButton(FUnderline, FBtnID + '_underline', '&#xE249;',FHints.Strings[2]);
  CreateButton(FStrikeThrough, FBtnID + '_strikethrough', '&#xE257;',FHints.Strings[3]);

  FFgClr := TWebColorPicker.Create(Self);
  FFgClr.Name := FBtnID + '_fgclr';
  FFgClr.Parent := Self;
  FFgClr.Align := alLeft;
  FFgClr.Hint := FHints.Strings[4];
  FFgClr.Width := 32;
  FFgClr.Height := 24;
  FFgClr.Color := clBlack;
  FFgClr.OnSelect := FgSelect;
  FFgClr.ShowHint := true;

  FBkClr := TWebColorPicker.Create(Self);
  FBkClr.Name := FBtnID + '_bkclr';
  FBkClr.Parent := Self;
  FBkClr.Align := alLeft;
  FBkClr.Hint := FHints.Strings[5];
  FBkClr.Width := 32;
  FBkClr.Height := 24;
  FBkClr.Color := clWhite;
  FBkClr.OnSelect := BkSelect;
  FBkClr.ShowHint := true;

  CreateButton(FAlignLeft, FBtnID + '_alignleft', '&#xE236;',FHints.Strings[6]);
  CreateButton(FAlignCenter, FBtnID + '_aligncenter', '&#xE234;',FHints.Strings[7]);
  CreateButton(FAlignRight, FBtnID + '_alignright', '&#xE237;',FHints.Strings[8]);

  CreateButton(FOList, FBtnID + '_olist','&#xE242;',FHints.Strings[9]);
  CreateButton(FUList, FBtnID + '_ulist','&#xE241;',FHints.Strings[10]);

  CreateButton(FURL, FBtnID + '_url', '&#xE250;',FHints.Strings[11]);

  CreateButton(FImage,  FBtnID + '_image', '&#xE3f4;',FHints.Strings[12]);

  FVisibleButtons := [reFont, reFontSize, reBold, reItalic, reUnderline, reStrikeThrough,
    reAlignLeft, reAlignCenter, reAlignRight, reUnorderedList, reOrderedList, reForegroundColor, reBackgroundColor, reHyperlink, reImageInsert, reLineSpacing];

  Width := 540;
  Height := 32;

  RichEdit := nil;

  FFilePicker := TJSHTMLElement(document.createElement('INPUT'));
  FFilePicker['type'] := 'file';
  FFilePicker.style.setProperty('visibility','hidden');
  FFilePicker.addEventListener('change', FPtrFileLoad);
  ElementHandle.appendChild(FFilePicker);

  FLineSpacing := TLineSpacingPicker.Create(Self);
  FLineSpacing.Name := FBtnID + '_linespacing';
  FLineSpacing.Parent := Self;
  FLineSpacing.Width := 40;
  FLineSpacing.Align := alLeft;
  FLineSpacing.ItemIndex := 0;
  FLineSpacing.ShowHint := true;
  FLineSpacing.Hint := FHints.Strings[13];
  FLineSpacing.OnChange := LineSpacingChange;
end;

destructor TRichEditToolBar.Destroy;
begin
  FHints.Free;
  if Assigned(FRichEdit) then
    TRichEditorCracker(FRichEdit).OnAttributesChanged := nil;

  inherited;
end;

procedure TRichEditToolBar.CreateButton(var btn: TSpeedButton; BtnID, Glyph, Hint: string);
begin
  btn := TSpeedButton.Create(BtnID);
  btn.Name := BtnID;
  btn.Parent := Self;
  btn.MaterialGlyph := Glyph;
  btn.Top := 0;
  btn.Width := 32;
  btn.Height := 24;
  btn.Align := alLeft;
  btn.Flat := true;
  btn.Hint := Hint;
  btn.ShowHint := Hint <> '';
  btn.Enabled := true;
  btn.OnClick := HandleSpeedButtonClick;
end;

procedure TRichEditToolBar.SetBackgroundColor(const Value: TColor);
begin
  FBackgroundColor := Value;
  FBkClr.Color := Value;
end;

procedure TRichEditToolBar.SetHints(const Value: TStrings);
begin
  FHints.Assign(Value);
end;

procedure TRichEditToolBar.SetParent(AValue: TControl);
begin
  inherited;

  if not (csLoading in ComponentState) then
    InitButtons;
end;

procedure TRichEditToolBar.SetRichEdit(const AValue: TRichEdit);
begin
  if Assigned(AValue) then
  begin
    FRichEdit := AValue;
    FRichEdit.Owner := Self;
    TRichEditorCracker(FRichEdit).OnAttributesChanged := HandleAttributeChanged;
  end;
end;

procedure TRichEditToolBar.SetTextColor(const Value: TColor);
begin
  FTextColor := Value;
  FFgClr.Color := Value;
end;

procedure TRichEditToolBar.SetVisibleButtons(const Value: TRichEditButtonSet);
begin
  FVisibleButtons := Value;
  UpdateButtons;
end;

procedure TRichEditToolBar.UpdateButtons;
begin
  if IsUpdating then
    Exit;

  FBold.Visible := reBold in FVisibleButtons;
  FItalic.Visible := reItalic in VisibleButtons;
  FUnderline.Visible := reUnderline in VisibleButtons;
  FStrikeThrough.Visible := reStrikeThrough in VisibleButtons;
  FAlignLeft.Visible := reAlignLeft in VisibleButtons;
  FAlignCenter.Visible := reAlignCenter in VisibleButtons;
  FAlignRight.Visible := reAlignRight in VisibleButtons;
  FUList.Visible := reUnorderedList in VisibleButtons;
  FOList.Visible := reOrderedList in VisibleButtons;
  FFnt.Visible := reFont in VisibleButtons;
  FFntSize.Visible := reFontSize in VisibleButtons;
  FFgClr.Visible := reForegroundColor in VisibleButtons;
  FBkClr.Visible := reBackgroundColor in VisibleButtons;
  FURL.Visible := reHyperlink in VisibleButtons;
  FImage.Visible := reImageInsert in VisibleButtons;
  FLineSpacing.Visible := reLineSpacing in VisibleButtons;

  if ElementPosition = epRelative then
  begin
    if Assigned(ElementHandle) then
      ElementHandle.style.setProperty('display','flex');
  end;

  FFnt.ElementPosition := ElementPosition;
  FFntSize.ElementPosition := ElementPosition;
  FBold.ElementPosition := ElementPosition;
  FItalic.ElementPosition := ElementPosition;
  FUnderline.ElementPosition := ElementPosition;
  FStrikeThrough.ElementPosition := ElementPosition;
  FFgClr.ElementPosition := ElementPosition;
  FBkClr.ElementPosition := ElementPosition;
  FAlignLeft.ElementPosition := ElementPosition;
  FAlignCenter.ElementPosition := ElementPosition;
  FAlignRight.ElementPosition := ElementPosition;
  FUList.ElementPosition := ElementPosition;
  FOList.ElementPosition := ElementPosition;
  FURL.ElementPosition := ElementPosition;
  FImage.ElementPosition := ElementPosition;
  FLineSpacing.ElementPosition := ElementPosition;

  while FHints.Count < 14 do
    FHints.Add('');

  FBold.Hint := FHints.Strings[0];
  FItalic.Hint := FHints.Strings[1];
  FUnderline.Hint := FHints.Strings[2];
  FStrikeThrough.Hint := FHints.Strings[3];
  FFgClr.Hint := FHints.Strings[4];
  FBkClr.Hint := FHints.Strings[5];

  FAlignLeft.Hint := FHints.Strings[6];
  FAlignCenter.Hint := FHints.Strings[7];
  FAlignRight.Hint := FHints.Strings[8];
  FUList.Hint := FHints.Strings[9];
  FOList.Hint := FHints.Strings[10];
  FURL.Hint := FHints.Strings[11];
  FImage.Hint := FHints.Strings[12];
  FLineSpacing.Hint := FHints.Strings[13];
end;

procedure TRichEditToolBar.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.SetProperty('height', '32px');
  end;
end;

procedure TRichEditToolBar.GetChildren(Proc: TGetChildProc; Root: TComponent);
begin
  // do nothing
end;

function TRichEditToolBar.GetRichEdit: TRichEdit;
begin
  Result := FRichEdit;
end;

procedure TRichEditToolBar.FgSelect(Sender: TObject);
begin
  if Assigned(RichEdit) then
    RichEdit.SelAttributes.Color := FFgClr.Color;
end;

procedure TRichEditToolBar.BkSelect(Sender: TObject);
begin
  if Assigned(RichEdit) then
    RichEdit.SelAttributes.BackColor := FBkClr.Color;
end;

procedure TRichEditToolBar.FntChange(Sender: TObject);
begin
  if Assigned(RichEdit) then
    RichEdit.SelAttributes.Name := FFnt.Items[FFnt.ItemIndex];
end;

procedure TRichEditToolBar.FntSizeChange(Sender: TObject);
begin
  if Assigned(RichEdit) then
    RichEdit.SelAttributes.Height := FFntSize.ItemIndex + 1;
end;

procedure TRichEditToolBar.HandleAttributeChanged(Sender: TObject; Attributes: TSelAttributes);
begin
  FFnt.ItemIndex := Max(0,FFnt.Items.IndexOf(Attributes.FontName));
  FFntSize.ItemIndex := Max(0,FFntSize.Items.IndexOf(Attributes.FontSize));
  FBold.Down := Attributes.isBold;
  FItalic.Down := Attributes.isItalic;
  FUnderline.Down := Attributes.isUnderline;
  FStrikeThrough.Down := Attributes.isStrikeThrough;

  FAlignLeft.Down := Attributes.isLeft;
  FAlignRight.Down := Attributes.isRight;
  FAlignCenter.Down := Attributes.isCenter;

  if Attributes.FontColor <> '' then
  begin
    FFgClr.Color := HexToColor(Attributes.FontColor);
  end;

  if Attributes.BkColor <> '' then
  begin
    FBkClr.Color := RgbToColor(Attributes.BkColor);
  end;
end;

function TRichEditToolBar.HandleDoFileLoad(Event: TEventListenerEvent): Boolean;

  procedure InsertImage(DataURL: string);
  begin
    RichEdit.InsertImage(DataURL);
  end;

begin
  if 1 < 0 then
    InsertImage('');

  asm
    var file = this.FFilePicker.files[0];
    var reader  = new FileReader();

    reader.addEventListener("load", function () {
        InsertImage(reader.result);
    }, false);

    if (file)
        reader.readAsDataURL(file);
  end;

  Result := true;
end;

procedure TRichEditToolBar.HandleSpeedButtonClick(Sender: TObject);
var
  BID: string;
begin
  if Assigned(RichEdit) then
  begin
    BID := TControl(Sender).GetID;

    if BID = FBtnID + '_bold' then
    begin
      if fsBold in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsBold]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsBold];
    end
    else if BID = FBtnID + '_italic' then
    begin
      if fsItalic in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsItalic]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsItalic];
    end
    else if BID = FBtnID + '_underline' then
    begin
      if fsUnderline in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsUnderline]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsUnderline];
    end
    else if BID = FBtnID + '_strikethrough' then
    begin
      if fsStrikeOut in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsStrikeOut]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsStrikeOut];
    end
    else if BID = FBtnID + '_alignleft' then
      RichEdit.SelAttributes.Alignment := taleftJustify
    else if BID = FBtnID + '_aligncenter' then
      RichEdit.SelAttributes.Alignment := taCenter
    else if BID = FBtnID + '_alignright' then
      RichEdit.SelAttributes.Alignment := taRightJustify
    else if BID = FBtnID + '_olist' then
      RichEdit.SelAttributes.OrderedList := not RichEdit.SelAttributes.OrderedList
    else if BID = FBtnID + '_ulist' then
      RichEdit.SelAttributes.UnOrderedList := not RichEdit.SelAttributes.UnOrderedList
    else if BID = FBtnID + '_url' then
      RichEdit.SelAttributes.Link
    else if BID = FBtnID + '_image' then
      FFilePicker.click();

  end;

  if Assigned(OnSpeedButtonClick) then
    OnSpeedButtonClick(Sender);
end;

{$HINTS OFF}
function TRichEditToolBar.InitButtons(): boolean;

  procedure HandleTimeOut;
  var
    i: integer;
  begin
    for i := 0 to ControlCount - 1 do
    begin
      Controls[i].UpdateElement;
    end;
  end;

begin
  Result := true;
  if not IsLinked then
    Exit;

  if (1 < 0) then
    HandleTimeOut;

  asm
    setTimeout(function() {HandleTimeOut();}, 10);
  end;
end;
{$HINTS ON}

procedure TRichEditToolBar.LineSpacingChange(Sender: TObject);
var
  spacing: single;
begin
  case FLineSpacing.ItemIndex of
  0: spacing := 1;
  1: spacing := 1.5;
  2: spacing := 2;
  end;
  FRichEdit.SetSelectionSpacing(spacing);
end;

procedure TRichEditToolBar.Loaded;
begin
  inherited;
  UpdateButtons;
  InitButtons;
end;

procedure TRichEditToolBar.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FRichEdit) then
  begin
    FRichEdit := nil;
  end;
end;

{ TToggleButton }

function TToggleButton.CreateElement: TJSElement;
begin
  Result := document.createElement('span');
  FLabel := TJSHTMLElement(document.createElement('label'));
  FInput := TJSHTMLElement(document.createElement('input'));
  FSlider := TJSHTMLElement(document.createElement('span'));
  FInput.setAttribute('type','checkbox');

  Result.appendChild(FLabel);
  FLabel.appendChild(FInput);
  FLabel.appendChild(FSlider);
end;

procedure TToggleButton.CreateInitialize;
var
  css: string;
begin
  inherited;

  css := '.switch {'+
    'position: relative;'+
    'display: inline-block;'+
    'width: 44px;'+
    'height: 22px;'+
  '}'+

  '.switch input {display:none;}'+

  '.slider {'+
    'position: absolute;'+
    'cursor: pointer;'+
    'top: 0;'+
    'left: 0;'+
    'right: 0;'+
    'bottom: 0;'+
    'background-color: #ccc;'+
    '-webkit-transition: .4s;'+
    'transition: .4s;'+
  '}'+

  '.slider:before {'+
    'position: absolute;'+
    'content: "";'+
    'height: 14px;'+
    'width: 14px;'+
    'left: 4px;'+
    'bottom: 4px;'+
    'background-color: white;'+
    '-webkit-transition: .4s;'+
    'transition: .4s;'+
  '}'+

  'input:checked + .slider {'+
    'background-color: #2196F3;'+
  '}'+

  'input:focus + .slider {'+
    'box-shadow: 0 0 1px #2196F3;'+
  '}'+

  'input:checked + .slider:before {'+
    '-webkit-transform: translateX(22px);'+
    '-ms-transform: translateX(22px);'+
    'transform: translateX(22px);'+
  '}'+

  '/* Rounded sliders */'+
  '.slider.round {'+
    'border-radius: 22px;'+
  '}'+

  '.slider.round:before {'+
    'border-radius: 50%;'+
  '}';

  AddControlStyle(css);

  Width := 48;
  Height := 24;
end;

function TToggleButton.GetChecked: boolean;
begin
  if Assigned(Container) then
    FChecked := TJSHTMLInputElement(FInput).checked;

  Result := FChecked;
end;

function TToggleButton.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  ElementEvent := Event;
  StopPropagation;

  if Event.target  = FInput then
  begin
    if Assigned(OnClick) then
      OnClick(Self);
  end;
  Result := True;
  ElementEvent := nil;
end;

procedure TToggleButton.Loaded;
begin
  inherited;
end;

procedure TToggleButton.SetChecked(const Value: boolean);
begin
  FChecked := Value;
  UpdateElement;
end;

procedure TToggleButton.SetStyle(const Value: TToggleButtonStyle);
begin
  if (FStyle <> Value) then
  begin
    FStyle := Value;
    UpdateElement;
  end;
end;

procedure TToggleButton.UpdateElement;
begin
  inherited;
  if not IsUpdating then
  begin
    InjectCSS;

    FLabel.setAttribute('class','switch');

    TJSHTMLInputElement(FInput).checked := FChecked;

    if Style = tsRectangular then
      FSlider.setAttribute('class','slider')
    else
      FSlider.setAttribute('class','slider round');
  end;
end;

{ TCustomBitBtn }

function TCustomBitBtn.CreateElement: TJSElement;
begin
  if FFlat then
    Result := document.createElement('SPAN')
  else
    Result := document.createElement('BUTTON');
end;

procedure TCustomBitBtn.CreateInitialize;
begin
  inherited;
  FColor := clNone;
  FIntColor := clNone;
  FFlat := false;
  Width := 80;
  Height := 25;
  FAlignment := taCenter;
  FGlyph := TURLPicture.Create;
  FGlyph.OnChange := GlyphChanged;
  FGlyph.OnDataChange := GlyphChanged;
  FGlyphSize := 0;
  FMaterialGlyphSize := 18;
  FMaterialGlyphColor := clNone;
  FMaterialGlyphType := mgNormal;
end;

destructor TCustomBitBtn.Destroy;
begin
  FGlyph.Free;
  inherited;
end;

procedure TCustomBitBtn.GlyphChanged(Sender: TObject);
begin
  UpdateElement;
end;

procedure TCustomBitBtn.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetColorEx(const Value: TColor);
begin
  if (FColor <> Value) then
  begin
    FColor := Value;
    inherited Color := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetFlat(const Value: boolean);
begin
  if (FFlat <> Value) then
  begin
    FFlat := Value;
    RecreateElement;
  end;
end;

procedure TCustomBitBtn.SetGlyph(const Value: TURLPicture);
begin
  FGlyph.Assign(Value);
  UpdateElement;
end;

procedure TCustomBitBtn.SetGlyphSize(const Value: integer);
begin
  if (FGlyphSize <> Value) then
  begin
    FGlyphSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetGlyphURL(const Value: String);
begin
  if FGlyphURL <> Value then
  begin
    FGlyphURL := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetIntColor(const Value: TColor);
begin
  FIntColor := Value;
end;

procedure TCustomBitBtn.SetAlignment(const Value: TAlignment);
begin
  if (FAlignment <> Value) then
  begin
    FAlignment := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetLayout(const Value: TButtonLayout);
begin
  if (FLayout <> Value) then
  begin
    FLayout := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetMaterialGlyph(const Value: TMaterialGlyph);
begin
  if (FMaterialGlyph <> Value) then
  begin
    FMaterialGlyph := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetMaterialGlyphSize(const Value: integer);
begin
  if (Value > 0) and (Value < 100) then
  begin
    FMaterialGlyphSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetMaterialGlyphType(const Value: TMaterialGlyphType);
begin
  if (FMaterialGlyphType <> Value) then
  begin
    FMaterialGlyphType := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetMaterialGyphColor(const Value: TColor);
begin
  if (FMaterialGlyphColor <> Value) then
  begin
    FMaterialGlyphColor := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.UpdateElement;
var
  s,inactive,noptr,matgl,imgsrc,matclr,cap,acc,gs: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if (FColor <> clNone) and not Flat then
      ElementHandle.style.setProperty('background-color', ColorToHTML(FColor))
    else
      ElementHandle.style.setProperty('background-color', '');

    ElementHandle.style.setProperty('vertical-align','middle');
    if Visible then
      ElementHandle.style.setProperty('display','table');
    ElementHandle.style.setProperty('overflow','hidden');
    ElementHandle.style.setProperty('padding','0px');

    case Alignment of
      taLeftJustify: ElementHandle.style.setProperty('text-align','left');
      taCenter: ElementHandle.style.setProperty('text-align','center');
      taRightJustify: ElementHandle.style.setProperty('text-align','right');
    end;

    s := '';
    inactive := '';
    noptr := '';
    matgl := '';

    if not Enabled then
      inactive := ' md-light md-inactive';

    noptr := 'pointer-events:none';

    if FMaterialGlyph <> '' then
    begin
      matclr := '';
      if FMaterialGlyphColor <> clNone then
        matclr := ';color:'+ColorToHTML(FMaterialGlyphColor);

      matgl := '<i class="'+GetMaterialClass(MaterialGlyphType)+ inactive +'" style="vertical-align:middle;'+noptr+';font-size:'+ IntToStr(FMaterialGlyphSize)+'px'+matclr+'">' + FMaterialGlyph + '</i>';
    end;

//    if (FMaterialGlyph <> '') and (Caption <> '') then
//      s := s + '&nbsp;';

    if (ElementHandle.childElementCount = 0) or not IsLinked then
      cap := ProcessAccelerator(Caption,acc);

    if acc <> '' then
      ElementHandle['accesskey'] := acc;


    if (cap <> '') then
      s := '<span style="'+noptr+'">' + cap + '</span>';

    gs := '';
    if GlyphSIze <> 0 then
      gs := 'width:'+GlyphSize.ToString+'px;';


    if FGlyph.Data <> '' then
      imgsrc :=  'data:image/jpeg;base64,' + HexImageDecodeAsBase64(FGlyph.Data)
    else
    if FGlyph.FileName <> '' then
      imgsrc := FGlyph.FileName
    else
    if FGlyphURL <> '' then
      imgsrc := FGlyphURL;

    case Layout of
    blGlyphLeft:
      begin
        if (matgl <> '') then
        begin
          if cap <> '' then
            s := matgl + '&nbsp;' + s
          else
            s := matgl;
        end;

        if imgsrc <> '' then
          s := '<img style="vertical-align:middle;' + gs + '" src="'+ imgsrc + '">&nbsp;' + s;
      end;

    blGlyphRight:
      begin
        if (matgl <> '') then
        begin
          if (cap <> '') then
            s := s + '&nbsp;' + matgl
          else
            s := matgl;
        end;

        if imgsrc <> '' then
           s := s+ '&nbsp;<img style="'+gs+'" src="'+ imgsrc + '">';
      end;

    blGlyphTop:
      begin
        if matgl <> '' then
        begin
          if cap <> '' then
            s := matgl + '<br>' + s
          else
            s := matgl;
        end;

        if imgsrc <> '' then
           s := '<img style="'+gs+'" src="'+ imgsrc + '"><br>' + s;
      end;

    blGlyphBottom:
      begin
        if matgl <> '' then
        begin
          if cap <> '' then
            s := s+ '<br>' + matgl
          else
            s := matgl;
        end;

        if imgsrc <> '' then
           s := s + '<br><img style="'+gs+'" src="'+ imgsrc + '">';
      end;
    end;

    ElementHandle.innerHTML := s;
    SetHTMLElementFont(TJSHTMLElement(ElementHandle), Font, not ((ElementClassName = '') and (ElementFont = efProperty)) );
  end;
end;

procedure TCustomBitBtn.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.removeProperty('outline');
    ElementHandle.style.removeProperty('user-select');
  end;

end;

end.

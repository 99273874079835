unit UAjoutSupprPatients;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmAjoutSupprPatient = class(TForm)
    WebListBox1: TListBox;
    WebListBox2: TListBox;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmAjoutSupprPatient: TfrmAjoutSupprPatient;

implementation

{$R *.dfm}

procedure TfrmAjoutSupprPatient.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'frmAjoutSupprPatient';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 480;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 640;
  finally
  end;
end;

end.  
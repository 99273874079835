unit Ufrmcible;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.Grids;

type
  TfrmCible = class(TForm)
    WebLabel1: TLabel;
    WebStringGrid1: TStringGrid;
    WebLabel2: TLabel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmCible: TfrmCible;

implementation

{$R *.dfm}

procedure TfrmCible.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebLabel2 := TLabel.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  try
    Name := 'frmCible';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 482;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 882;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Feuille cible';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -26;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 20;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 31;
    WebLabel1.Left := 354;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 29;
    WebLabel1.Width := 141;
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.ColCount := 5;
    WebStringGrid1.DefaultRowHeight := 22;
    WebStringGrid1.Font.Charset := ANSI_CHARSET;
    WebStringGrid1.Font.Color := clBlack;
    WebStringGrid1.Font.Height := -11;
    WebStringGrid1.Font.Name := 'Arial';
    WebStringGrid1.Font.Size := 8;
    WebStringGrid1.Font.Style := [];
    WebStringGrid1.Height := 368;
    WebStringGrid1.Left := 41;
    WebStringGrid1.Options := [goVertLine,goHorzLine,goRangeSelect];
    WebStringGrid1.RowCount := 5;
    WebStringGrid1.StyleElements := [];
    WebStringGrid1.TabOrder := 0;
    WebStringGrid1.Top := 85;
    WebStringGrid1.Width := 792;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Patient:';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -18;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 14;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 22;
    WebLabel2.Left := 41;
    WebLabel2.ParentFont := False;
    WebLabel2.Top := 60;
    WebLabel2.Width := 63;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
  end;
end;

end.  
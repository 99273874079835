unit Unit9;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TForm9 = class(TForm)
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebEdit1: TEdit;
    WebLabel3: TLabel;
    WebEdit2: TEdit;
    WebPanel1: TPanel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebEdit3: TEdit;
    procedure WebLabel3Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form9: TForm9;

implementation

{$R *.dfm}

procedure TForm9.WebLabel3Click(Sender: TObject);
begin
  
end;

procedure TForm9.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Form9';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
  finally
  end;
end;

end.
unit Ufrmmedicaments;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.Grids, WEBLib.ComCtrls;

type
  TfrmMedicaments = class(TForm)
    WebLabel1: TLabel;
    WebButton1: TButton;
    WebTabSet1: TTabSet;
    WebStringGrid1: TStringGrid;
    WebButton2: TButton;
    procedure Form3Create(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmMedicaments: TfrmMedicaments;

implementation

{$R *.dfm}





procedure TfrmMedicaments.WebButton1Click(Sender: TObject);
begin
close;  
end;

procedure TfrmMedicaments.Form3Create(Sender: TObject);
begin
WebStringGrid1.Width:=Integer(window.screen['width']) * 75 div 100;
WebStringGrid1.ColWidths[0]:=WebStringGrid1.width * 10 div 100;  
WebStringGrid1.ColWidths[1]:=WebStringGrid1.width * 40 div 100;  
WebStringGrid1.ColWidths[2]:=WebStringGrid1.width * 40 div 100;  
WebStringGrid1.ColWidths[3]:=WebStringGrid1.width * 10 div 100;  
WebStringGrid1.cells[0,0]:='Qte à commander';
 WebStringGrid1.cells[1,0]:='Nom';
  WebStringGrid1.cells[2,0]:='Prescription';
  WebStringGrid1.cells[3,0]:='Archivé le';

end;

procedure TfrmMedicaments.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebTabSet1 := TTabSet.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebButton2 := TButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebTabSet1.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'frmMedicaments';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 482;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'Form3Create');
    TabOrder := 0;
    Top := 0;
    Width := 1042;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Médicaments';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 26;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 39;
    WebLabel1.Left := 365;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 24;
    WebLabel1.Width := 206;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Fermer';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 16;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 24;
    WebButton1.Width := 100;
    WebTabSet1.SetParentComponent(Self);
    WebTabSet1.Name := 'WebTabSet1';
    WebTabSet1.Color := clWindow;
    WebTabSet1.Font.Charset := ANSI_CHARSET;
    WebTabSet1.Font.Color := clBlack;
    WebTabSet1.Font.Height := -11;
    WebTabSet1.Font.Name := 'Arial';
    WebTabSet1.Font.Size := 8;
    WebTabSet1.Font.Style := [];
    WebTabSet1.Height := 320;
    WebTabSet1.ItemIndex := 0;
    WebTabSet1.Items.BeginUpdate;
    try
      WebTabSet1.Items.Clear;
      WebTabSet1.Items.Add('Traitement en cours');
      WebTabSet1.Items.Add('Historique');
    finally
      WebTabSet1.Items.EndUpdate;
    end;
    WebTabSet1.Left := 16;
    WebTabSet1.ParentFont := False;
    WebTabSet1.TabOrder := 0;
    WebTabSet1.Top := 88;
    WebTabSet1.Width := 880;
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.ColCount := 4;
    WebStringGrid1.DefaultRowHeight := 22;
    WebStringGrid1.Font.Charset := ANSI_CHARSET;
    WebStringGrid1.Font.Color := clBlack;
    WebStringGrid1.Font.Height := -11;
    WebStringGrid1.Font.Name := 'Arial';
    WebStringGrid1.Font.Size := 8;
    WebStringGrid1.Font.Style := [];
    WebStringGrid1.Height := 304;
    WebStringGrid1.Left := 16;
    WebStringGrid1.Options := [goVertLine,goHorzLine,goRangeSelect];
    WebStringGrid1.RowCount := 5;
    WebStringGrid1.StyleElements := [];
    WebStringGrid1.TabOrder := 0;
    WebStringGrid1.Top := 104;
    WebStringGrid1.Width := 880;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Caption := 'Envoyer la liste';
    WebButton2.Color := clNone;
    WebButton2.Default := False;
    WebButton2.Font.Charset := ANSI_CHARSET;
    WebButton2.Font.Color := clBlack;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Size := 8;
    WebButton2.Font.Style := [];
    WebButton2.Height := 25;
    WebButton2.Left := 16;
    WebButton2.Role := 'button';
    WebButton2.TabOrder := 0;
    WebButton2.Top := 417;
    WebButton2.Width := 100;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebTabSet1.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.             
unit Ufrmanamnese;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TfrmAnamnese = class(TForm)
    WebLabel1: TLabel;
    txtNom: TEdit;
    WebLabel2: TLabel;
    WebButton1: TButton;
    txtPrenom: TEdit;
    WebLabel3: TLabel;
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    editmode : String;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmAnamnese: TfrmAnamnese;

implementation



{$R *.dfm}


procedure TfrmAnamnese.WebButton1Click(Sender: TObject);
begin
  close;
end;

procedure TfrmAnamnese.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  txtNom := TEdit.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  txtPrenom := TEdit.Create(Self);
  WebLabel3 := TLabel.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  txtNom.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  txtPrenom.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  try
    Name := 'frmAnamnese';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 442;
    Left := 0;
    Shadow := False;
    TabOrder := 0;
    Top := 0;
    Width := 626;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Caption := 'Anamnèse';
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Size := 26;
    WebLabel1.Font.Style := [];
    WebLabel1.Height := 39;
    WebLabel1.Left := 313;
    WebLabel1.ParentFont := False;
    WebLabel1.Top := 22;
    WebLabel1.Width := 166;
    txtNom.SetParentComponent(Self);
    txtNom.Name := 'txtNom';
    txtNom.Alignment := taLeftJustify;
    txtNom.Color := clWindow;
    txtNom.Font.Charset := ANSI_CHARSET;
    txtNom.Font.Color := clBlack;
    txtNom.Font.Height := -11;
    txtNom.Font.Name := 'Arial';
    txtNom.Font.Size := 8;
    txtNom.Font.Style := [];
    txtNom.Height := 24;
    txtNom.HideSelection := False;
    txtNom.Left := 96;
    txtNom.ShowFocus := True;
    txtNom.TabOrder := 0;
    txtNom.Top := 73;
    txtNom.Width := 192;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Caption := 'Nom';
    WebLabel2.Font.Charset := ANSI_CHARSET;
    WebLabel2.Font.Color := clBlack;
    WebLabel2.Font.Height := -11;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Size := 8;
    WebLabel2.Font.Style := [];
    WebLabel2.Height := 12;
    WebLabel2.Left := 26;
    WebLabel2.Top := 73;
    WebLabel2.Width := 22;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'Fermer';
    WebButton1.Color := clNone;
    WebButton1.Default := False;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 26;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 22;
    WebButton1.Width := 100;
    txtPrenom.SetParentComponent(Self);
    txtPrenom.Name := 'txtPrenom';
    txtPrenom.Alignment := taLeftJustify;
    txtPrenom.Color := clWindow;
    txtPrenom.Font.Charset := ANSI_CHARSET;
    txtPrenom.Font.Color := clBlack;
    txtPrenom.Font.Height := -11;
    txtPrenom.Font.Name := 'Arial';
    txtPrenom.Font.Size := 8;
    txtPrenom.Font.Style := [];
    txtPrenom.Height := 24;
    txtPrenom.HideSelection := False;
    txtPrenom.Left := 96;
    txtPrenom.ShowFocus := True;
    txtPrenom.TabOrder := 0;
    txtPrenom.Top := 124;
    txtPrenom.Width := 192;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Caption := 'Prénom';
    WebLabel3.Font.Charset := ANSI_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -11;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Size := 8;
    WebLabel3.Font.Style := [];
    WebLabel3.Height := 12;
    WebLabel3.Left := 26;
    SetEvent(WebLabel3, Self, 'OnClick', 'WebLabel3Click');
    WebLabel3.Top := 124;
    WebLabel3.Width := 37;
  finally
    WebLabel1.AfterLoadDFMValues;
    txtNom.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    txtPrenom.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
  end;
end;

end.       